class LocalStorageService {
  getCurrentLocation(currentId) {
    var currentLocation = { show: "", hide: "" };
    switch (currentId) {
      case 1:
        currentLocation = { show: "d-block", hide: "d-none" };
        break;
      case 2:
        currentLocation = { show: "d-block", hide: "d-none" };
        break;
      case 3:
        currentLocation = { show: "", hide: "" };
        break;
      case 4:
        currentLocation = { show: "", hide: "" };
        break;
      case 5:
        currentLocation = { show: "", hide: "" };
        break;
      case 6:
        currentLocation = { show: "", hide: "" };
        break;
      case 7:
        currentLocation = { show: "", hide: "" };
        break;
      case 8:
        currentLocation = { show: "", hide: "" };
        break;
      case 9:
        currentLocation = { show: "", hide: "" };
        break;
      case 10:
        currentLocation = { show: "", hide: "" };
        break;
      case 11:
        currentLocation = { show: "", hide: "" };
        break;
      case 12:
        currentLocation = { show: "", hide: "" };
        break;
      case 13:
        currentLocation = { show: "", hide: "" };
        break;
      case 14:
        currentLocation = { show: "", hide: "" };
        break;
      case 15:
        currentLocation = { show: "", hide: "" };
        break;

      default:
        currentLocation = { show: "", hide: "" };
    }
    return currentLocation;
  }
}

export default new LocalStorageService();
