<template>
  <div class="d-flex justify-content-center align-items-center">
    <div class="mainCircle">
      <button
        id="inputEditButton"
        type="button"
        :class="
          isLanguageEN ? 'btn btnEdit bg-white' : 'btn AR-Lang-btnEdit bg-white'
        "
      >
        <img :src="editIcon" alt="editIcon" />
      </button>
      <div class="profileDiv">
        <img
          id="profileImage"
          :src="checkImage ? avatarImage : avatarImage"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import editIcon from "../../../assets/Icons/editIcon.svg";
import avatarImage from "../../../assets/img/avetar.142bfcda.png";
import Resumable from "resumablejs";

export default {
  name: "Avatar.Vue",
  data: function () {
    return {
      editIcon,
      avatarImage,
      selectedImg: "",
      checkImage: false,
      isLanguageEN: true,
    };
  },
  mounted() {
    this.initResumable();
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
  },
  methods: {
    initResumable: function () {
      let portalid = 1;
      let projectid = 1;
      let dnnInstallationpath = "";
      // setTimeout(() => {
      //   const container = this.$el.querySelector('passportImg');
      //   container.scrollTop = container.scrollHeight;
      // }, 3000);
      let uploaderimage = document.getElementById("inputEditButton");

      // console.log(uploaderimage);

      // let uploaderimage = document.querySelector('passportImage01')
      let imagetype = ["bmp", "gif", "png", "jpeg", "JPG"];
      //this.resumable(imagetype, 'http://localhost:61709/api/FileUpload/upload', portalid, uploaderimage, 2, projectid,dnnInstallationpath)
      this.resumable(
        imagetype,
        "http://login.emerge.lk:9090/MyPorterFileUploaderAPI/api/FileUploader/Upload",
        portalid,
        uploaderimage,
        2,
        projectid,
        dnnInstallationpath
      );
    },

    resumable(
      filetype,
      target,
      portalid,
      element,
      type,
      projectid,
      dnnInstallationpath
    ) {
      let self = this;
      let resumableObject = {
        target: target,
        query: {
          PortalId: portalid,
          ProjectId: projectid,
          dnnInstallationPath: dnnInstallationpath,
        },
        simultaneousUploads: 10,
        fileType: filetype,
        maxFiles: 10,
      };

      let r = new Resumable(resumableObject);
      r.assignBrowse(element);
      r.on("progress", function () {
        console.debug("progress");
      });

      r.on("fileAdded", function (file) {
        var filesize = Math.round(file.size / 1048576);
        if (filesize < 71) {
          self.isUploading = true;
          self.isPosted = true;
          if (type == 3) {
            r.upload();
            // if(gtDocsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 1) {
            r.upload();
            //  if(gtVideoValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 2) {
            r.upload();
            // if(gtGraphicsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          }
        } else {
          //  self.clearFileUplloadStuff();
          //  self.eventBus.$emit('showMessage', 'File is too large. Please upload a file which is less than 70MB', 'warn')
        }
      });

      r.on("fileSuccess", function (file, message) {
        // console.log("message", message);
        // console.log("files", file);
        self.avatarImage = message;
      });

      r.on("fileError", function (file) {
        // console.log(file);
        //  self.clearFileUplloadStuff();
        // self.eventBus.$emit('showMessage', 'An error occurred', 'warn')
      });
    },
  },
};
</script>

<style scoped>
.btnEdit {
  position: relative;
  top: 12px;
  left: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid #c6c9cc;
  z-index: 5;
}

.AR-Lang-btnEdit {
  position: relative;
  top: 12px;
  left: -13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid #c6c9cc;
  z-index: 5;
}
.btnEdit img {
  width: 13px;
}

.profileDiv {
  position: relative;
  top: -17px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 1px solid #c6c9cc;
}

#profileImage {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  background-position: center;
}

.mainCircle {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 2px dashed #c6c9cc;
}
</style>
