<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              this.isPrimaryPassenger
                ? "Edit Passenger Details"
                : "Additional Passenger Details"
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearTextFields"
          ></button>
        </div>
        <div class="modal-body">
          <form action="" id="additionalPassengers">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.firstName
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="fName"
                type="text"
                class="form-control"
                placeholder="First Name"
                v-model="passangerFirstName"
              />
              <small
                id="fNameError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerFirstName }}</small
              >
            </div>

            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.lastName
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="lName"
                type="text"
                class="form-control"
                placeholder="Last Name"
                v-model="passangerLastName"
              />
              <small
                id="lNameError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerLastName }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.email
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="email"
                type="Email"
                class="form-control"
                placeholder="Email"
                v-model="passangerEmail"
                disabled
                hidden
              />
              <small
                id="emailError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerEmail }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.contact
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="phone"
                type="text"
                class="form-control"
                placeholder="Mobile"
                v-model="passangerContactNumber"
                disabled
                hidden
              />
              <small
                id="contactError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerContactNumber }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.address
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="address"
                type="text"
                class="form-control"
                placeholder="Address"
                v-model="passangerAddress"
                disabled
                hidden
              />
              <small
                id="addressError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerAddress }}</small
              >
            </div>

            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.passportNumber
              }}</label>
              <input
                id="passNumber"
                type="text"
                class="form-control"
                placeholder="Passport Number"
                v-model="passangerPassportNumber"
              />
              <small
                id="passNumberError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerPassportNumber }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.flightNumber
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="flightNumber"
                type="text"
                class="form-control"
                v-model="passangerFlightNumber"
                placeholder="Flight Number"
              />
              <small
                id="flightNumberError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerFlightNumber }}</small
              >
            </div>

            <div class="row">
              <div id="passportImgDiv" class="col-lg-5 col-sm-12 ps-lg-">
                <div class="form-group" id="firstChild">
                  <label class="opacity-50 mb-1" for="passportImg">{{
                    generalDetails.passport_Photo
                  }}</label>

                  <div
                    id="passportPhotoDivMnSecondary"
                    class="
                      row
                      passportPhotoDiv
                      form-control
                      border-1
                      d-flex
                      align-items-end
                    "
                    @dragover="changeAppiarance($event)"
                    @dragleave="removeAppiarance($event)"
                    @drop="dropFile($event)"
                  >
                    <div
                      class="
                        col-12
                        d-flex
                        justify-content-center
                        align-items-end
                      "
                    >
                      <img :src="uploadIcon" alt="" class="pt-4" />
                    </div>
                    <div class="col-12">
                      <div
                        style="font-size: 10px; curson: pointer !important"
                        id="passportImg"
                        onfocus="(this.type='file')"
                        :required="requardFile"
                        type="text"
                        :class="`passportImg form-control ${
                          errors.passangerPassportImage ? 'is-invalid' : ''
                        }`"
                        name="passangerPassportImage"
                        aria-describedby="passportPhotoError"
                        @change="displayPassportPhoto($event)"
                      >
                        Drag and Drop or
                        <b class="uploadTextColor">Browse</b> to upload
                      </div>
                      <small
                        id="passportPhotoError"
                        class="
                          me-lg-5
                          mt-lg-3
                          form-text
                          text-muted
                          invalid-feedback
                        "
                        >{{ errors.passangerPassportImage }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="passportImgDiv"
                class="
                  col-lg-5 col-sm-12
                  ps-lg-0
                  d-flex
                  justify-content-center
                  align-item-center
                "
              >
                <div v-show="!springLoader">
                  <Spinner />
                </div>
                <img
                  class="passportImgPreview d-none"
                  id="passportPhotoSecondary"
                  :src="
                    checkImage ? passangerPassportImage : passangerPassportImage
                  "
                  height="200"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="btnCloseModal"
            type="button"
            class="btn btnNo"
            data-bs-dismiss="modal"
            @click="clearTextFields"
          >
            Close
          </button>
          <button
            id="btnYes"
            type="button"
            class="btn btnYes"
            @click="addSecondaryPassenger"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadIcon from "../../../assets/Icons/upload.svg";
import Resumable from "resumablejs";
import vuex from "vuex";
import validateGeneralDetailsInput from "../../../validations/GeneralDetails/GeneralDetailsValidation";
import Spinner from "../../../common/components/Spinner.vue";
import VueSweetalert2 from "sweetalert2";
import moment from "moment";
import UserService from "../../../services/UserService";
export default {
  name: "SecondaryUserForm",
  components: {
    Spinner,
  },
  props: {
    fNumber: {
      type: String,
      required: true,
    },
    psNumber: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    indexNumber: {
      type: Number,
      required: true,
    },
    isPrimaryPassenger: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      uploadIcon,
      generalDetails: this.$store.getters.getGeneralDetailsLabel,
      errors: {},
      springLoader: false,
      imageUrl: "",
      passangerFirstName: "",
      passangerLastName: "",
      passangerEmail: "",
      passangerContactNumber: "",
      passangerAddress: "",
      passangerPassportNumber: "",
      passangerFlightNumber: this.fNumber,
      passangerPassportImage: "",
      lgPassengerFlightNumber: this.fNumber ? this.fNumber : "",
      lgPassengerPassportNumber: this.psNumber ? this.psNumber : "",
      savedPrimaryPassenger: [],
      isEdit: this.edit ? true : false,
      isPrimaryPassenger: false,
    };
  },
  mounted() {
    this.initResumable("passportPhotoDivMnSecondary");

    // console.log("isEdit", this.isEdit);

    // if (this.currentUser) {
    //   const getPrimaryPassengerDetails =
    //     this.$store.getters.getPrimaryPassengerDetails;
    //   this.bindPrimaryPassengerDetails(getPrimaryPassengerDetails);
    // }
  },
  computed: {
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapGetters(["getSecondaryPassengerDetails"]),
    ...vuex.mapGetters(["getEmailVerificationStatus"]),
    ...vuex.mapGetters(["currentUser"]),
    ...vuex.mapGetters(["setGeneralDetails"]),
    ...vuex.mapGetters(["getPrimaryEdit"]),
  },
  watch: {
    edit: function () {
      this.editSecondaryPassenger();
    },
    getPrimaryEdit: function (val) {
      if (val) {
        // this.isPrimaryPassenger = true;
        const getPrimaryPassengerDetails =
          this.$store.getters.getPrimaryPassengerDetails;
        // console.log("getPrimaryPassengerDetails", getPrimaryPassengerDetails);

        // this.bindPrimaryPassengerDetails(getPrimaryPassengerDetails);
      } else {
        this.isPrimaryPassenger = false;
      }
    },
  },
  methods: {
    ...vuex.mapActions(["setSecondaryDetails"]),
    ...vuex.mapActions(["setUpdateSecondaryDetails"]),
    ...vuex.mapGetters(["getSecondaryPassengerDetails"]),
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapActions(["setGeneralDetails"]),
    ...vuex.mapActions(["deleteSecondaryPassenger"]),

    initResumable: function (element) {
      let portalid = 1;
      let projectid = 1;
      let dnnInstallationpath = "";

      let uploaderimage = document.getElementById(element);

      let actions = this;

      actions.springLoader = true;

      // let uploaderimage = document.querySelector('passportImage01')
      let imagetype = ["bmp", "gif", "png", "jpeg", "JPG"];
      //this.resumable(imagetype, 'http://localhost:61709/api/FileUpload/upload', portalid, uploaderimage, 2, projectid,dnnInstallationpath)
      this.resumable(
        imagetype,
        "http://login.emerge.lk:9090/MyPorterFileUploaderAPI/api/FileUploader/Upload",
        portalid,
        uploaderimage,
        2,
        projectid,
        dnnInstallationpath
      );
    },

    resumable(
      filetype,
      target,
      portalid,
      element,
      type,
      projectid,
      dnnInstallationpath
    ) {
      let self = this;
      let resumableObject = {
        target: target,
        query: {
          PortalId: portalid,
          ProjectId: projectid,
          dnnInstallationPath: dnnInstallationpath,
        },
        simultaneousUploads: 10,
        fileType: filetype,
        maxFiles: 10,
      };

      let r = new Resumable(resumableObject);
      r.assignBrowse(element);
      r.on("progress", function () {
        console.debug("progress");
      });

      r.on("fileAdded", function (file) {
        var filesize = Math.round(file.size / 1048576);
        if (filesize < 71) {
          self.isUploading = true;
          self.isPosted = true;
          if (type == 3) {
            r.upload();
            // if(gtDocsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 1) {
            r.upload();
            //  if(gtVideoValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 2) {
            r.upload();
            // if(gtGraphicsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          }
        } else {
          //  self.clearFileUplloadStuff();
          //  self.eventBus.$emit('showMessage', 'File is too large. Please upload a file which is less than 70MB', 'warn')
        }
      });

      r.on("fileSuccess", function (file, message) {
        // var tempFileName = file.fileName;
        // const imgHello = tempFileName;

        self.imageUrl = message;

        self.springLoader = false;

        setTimeout(() => {
          self.springLoader = true;
          document.getElementById("passportPhotoSecondary").src = message;
          document
            .getElementById("passportPhotoSecondary")
            .classList.remove("d-none");
          document
            .getElementById("passportPhotoSecondary")
            .classList.add("d-block");
        }, 3000);
      });

      r.on("fileError", function (file) {
        this.imageUrl = "http://myporterqa.emerge.lk/img/bg/contact.png";

        //  self.clearFileUplloadStuff();
        // self.eventBus.$emit('showMessage', 'An error occurred', 'warn')
      });
    },

    addSecondaryPassenger() {
      if (this.edit) {
        this.updateSecondaryPassenger();
      } else {
        if (
          this.passangerFirstName &&
          this.passangerLastName &&
          this.passangerPassportNumber
        ) {
          const passDepDate =
            this.$store.getters.getPrimaryPassengerDetails[0].passangeDepDate;

          const secondaryPassenger = {
            passangerID: 0,
            passangerFirstName: this.passangerFirstName,
            passangerLastName: this.passangerLastName,
            passangerEmail: this.passangerEmail,
            passangerContactNumber: this.passangerContactNumber,
            passangerAddress: this.passangerAddress,
            passangerPassportNumber: this.passangerPassportNumber,
            passangerPassportImage: this.imageUrl,
            passangerIsPrimary: 1,
            passangerFlightNumber: this.passangerFlightNumber,
            passangeDepDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            isActive: false,
          };

          try {
            if (this.imageUrl === undefined) {
              this.passangerPassportImage = "";
            } else {
              this.passangerPassportImage = document.getElementById(
                "passportPhotoSecondary"
              ).src;
            }

            this.setSecondaryDetails(secondaryPassenger);

            const a = this.$store.getters.getSecondaryPassengerDetails;

            document
              .getElementById("passportPhotoSecondary")
              .removeAttribute("src");
            document
              .getElementById("passportPhotoSecondary")
              .classList.remove("d-block");
            document
              .getElementById("passportPhotoSecondary")
              .classList.add("d-none");
            this.clearTextFields();

            document.getElementById("additionalPassengers").reset();

            let modalClose = document.getElementById("btnCloseModal");
            modalClose.click();

            this.clearTextFields();

            this.isEdit = false;
          } catch (e) {
            // console.log(e);
          }
        }
      }
    },

    updateSecondaryPassenger() {
      if (this.edit) {
        VueSweetalert2.fire({
          title: "Are you sure?",
          text: "You want to update your details!",
          showCancelButton: true,
          confirmButtonColor: "#006203",
          cancelButtonColor: "#F05C54",
          confirmButtonText: "Yes, Update it!",
        }).then((result) => {
          if (result.isConfirmed) {
            if (
              this.passangerFirstName &&
              this.passangerLastName &&
              this.passangerPassportNumber
            ) {
              const passDepDate =
                this.$store.getters.getPrimaryPassengerDetails[0]
                  .passangeDepDate;

              const secondaryPassenger = {
                passangerID: 0,
                passangerFirstName: this.passangerFirstName,
                passangerLastName: this.passangerLastName,
                passangerEmail: this.passangerEmail,
                passangerContactNumber: this.passangerContactNumber,
                passangerAddress: this.passangerAddress,
                passangerPassportNumber: this.passangerPassportNumber,
                passangerPassportImage: this.imageUrl,
                passangerIsPrimary: 1,
                passangerFlightNumber: this.passangerFlightNumber,
                passangeDepDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                isActive: false,
              };

              try {
                if (this.imageUrl === undefined) {
                  this.passangerPassportImage = "";
                } else {
                  this.passangerPassportImage = document.getElementById(
                    "passportPhotoSecondary"
                  ).src;
                }

                this.deleteSecondaryPassenger(this.indexNumber);

                // this.setSecondaryDetails(secondaryPassenger);
                this.setUpdateSecondaryDetails(secondaryPassenger);

                const a = this.$store.getters.getSecondaryPassengerDetails;

                document
                  .getElementById("passportPhotoSecondary")
                  .removeAttribute("src");
                document
                  .getElementById("passportPhotoSecondary")
                  .classList.remove("d-block");
                document
                  .getElementById("passportPhotoSecondary")
                  .classList.add("d-none");
                this.clearTextFields();

                let modalClose = document.getElementById("btnCloseModal");
                modalClose.click();

                this.clearTextFields();

                this.edit = false;
              } catch (e) {
                // console.log(e);
              }
            }
          }
        });
      }
    },

    editSecondaryPassenger() {
      var isPrimaryPassengerEdit = sessionStorage.getItem("isPrimaryPassenger");
      // console.log("isPrimaryPassengerEdit", isPrimaryPassengerEdit);
      if (isPrimaryPassengerEdit) {
        this.isPrimaryPassenger = isPrimaryPassengerEdit;
        $("#exampleModal").modal("show");
        const primaryPassenger = JSON.parse(
          sessionStorage.getItem("primaryPassenger")
        );
        console.log("primaryPassenger", primaryPassenger);
        this.passangerEmail = primaryPassenger[0].passangerEmail;
        this.passangerContactNumber =
          primaryPassenger[0].passangerContactNumber;

        this.passangerFirstName = primaryPassenger[0].passangerFirstName;
        this.passangerLastName = primaryPassenger[0].passangerLastName;
      }

      if (this.edit) {
        $("#exampleModal").modal("show");
        const secondaryDetails =
          this.$store.getters.getSecondaryPassengerDetails;
        // console.log(this.$store.getters.getSecondaryPassengerDetails);
        console.log("secondaryDetails", secondaryDetails);

        this.passangerFirstName =
          secondaryDetails[this.indexNumber].passangerFirstName;
        this.passangerLastName =
          secondaryDetails[this.indexNumber].passangerLastName;
        this.passangerEmail = secondaryDetails[this.indexNumber].passangerEmail;
        this.passangerContactNumber =
          secondaryDetails[this.indexNumber].passangerContactNumber;
        this.passangerAddress =
          secondaryDetails[this.indexNumber].passangerAddress;
        this.passangerPassportNumber =
          secondaryDetails[this.indexNumber].passangerPassportNumber;
        this.passangerFlightNumber =
          secondaryDetails[this.indexNumber].passangerFlightNumber;
        this.passangerPassportImage =
          secondaryDetails[this.indexNumber].passangerPassportImage;
      } else {
        $("#exampleModal").modal("hide");
      }
    },

    clearTextFields() {
      this.passangerFirstName = "";
      this.passangerLastName = "";
      this.passangerPassportNumber = "";
    },

    bindPrimaryPassengerDetails(updatePrimaryPassenger) {
      $("#exampleModal").modal("show");
      const fNum = document.getElementById("flightNumber").value;
      const psNum = document.getElementById("passNumber").value;

      this.passangerEmail = updatePrimaryPassenger[0].customerEmail;
      this.passangerContactNumber = updatePrimaryPassenger[0].customerNumber;
      this.passangerFlightNumber = psNum;

      this.passangerFirstName = updatePrimaryPassenger[0].passangerFirstName;
      this.passangerLastName = updatePrimaryPassenger[0].passangerLastName;

      this.passangerFlightNumber =
        updatePrimaryPassenger[0].passangerPassportNumber;

      const primaryPassenger = {
        passangerID: updatePrimaryPassenger[0].passengerId,
        passangerFirstName: updatePrimaryPassenger[0].passangerFirstName,
        passangerLastName: updatePrimaryPassenger[0].passangerLastName,
        passangerEmail: updatePrimaryPassenger[0].passangerEmail,
        passangerContactNumber:
          updatePrimaryPassenger[0].passangerContactNumber,
        passangerAddress: null,
        passangerPassportNumber: psNum,
        passangerPassportImage: null,
        passangerIsPrimary: 1,
        passangerFlightNumber: fNum,
        passangeDepDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        isActive: false,
      };
      // this.setGeneralDetails(primaryPassenger);
    },
  },
};
</script>

<style>
.modal-backdrop.show {
  opacity: 0 !important;
}

.modal-backdrop {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
}

.btnNo {
  width: 100px !important;
  background: #db0e03 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  border: 1px solid #e8e9eb !important;
}

.btn-close {
  border-radius: 100% !important;
}

.modal-content {
  border-radius: 20px !important;
  background-color: #ffffff !important;
  box-shadow: 0 10px 8px #e5e5e5 !important;
}

.btnYes {
  width: 100px !important;
  background: #006203 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}

.modal-footer {
  border-top: none;
}

.passportPhotoDiv {
  border: 2px dashed #c6c9cc !important;
  background: #d1eed2 !important;
  box-sizing: border-box !important;
  border-radius: 14px !important;
  opacity: 0.8 !important;
  margin-left: 0 !important;
}
.changeBackground {
  border: 2px solid #006203 !important;
}
.passportImg {
  border: none !important;
  border-radius: 0 !important;
  text-align: center !important;
  background: none !important;
}
</style>
