import validator from "validator";
import GeneralDetailsService from "../../services/GeneralDetailsService";
const validateGeneralDetailsInput = (data) => {
  let errors = {};
  const hi = "hello";
  const {
    Profile,
    passangerFirstName,
    passangerLastName,
    passangerEmail,
    passangerContactNumber,
    passangerAddress,
    passangerPassportNumber,
    passangerFlightNumber,
    passangeDepDate,
    passangeDepTime,
    passangerPassportImage,
  } = data;

  if (validator.isEmpty(passangerFirstName)) {
    errors.passangerFirstName = "Input your first name";
  } else if (passangerFirstName === undefined) {
    errors.passangerFirstName = "Input your first name";
  } else {
    const pattern = /^[a-zA-Z ]*$/;
    if (pattern.test(passangerLastName) === false) {
      errors.passangerFirstName = "Input your first name in alphabets";
    }
  }

  if (validator.isEmpty(passangerLastName)) {
    errors.passangerLastName = "Input your last name";
  } else if (passangerLastName === undefined) {
    errors.passangerLastName = "Input your last name";
  } else {
    const pattern = /^[a-zA-Z ]*$/;
    if (pattern.test(passangerLastName) === false) {
      errors.passangerLastName = "Input your last name in alphabets";
    }
  }

  if (validator.isEmpty(passangerEmail)) {
    errors.passangerEmail = "Input your email";
  } else if (passangerEmail === undefined) {
    errors.passangerEmail = "Input your email";
  } else {
    let chekEmail = false;

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(passangerEmail)) {
      chekEmail = true;
    } else {
      errors.passangerEmail = "Input valid email";
    }
  }

  if (validator.isEmpty(passangerContactNumber)) {
    errors.passangerContactNumber = "Input your contact";
  } else if (passangerContactNumber === undefined) {
    errors.passangerContactNumber = "Input your contact";
  } else {
    const validContactNumberRegex = RegExp(/^(\+\d{1,9}[- ]?)?\d{10}$/);

    if (!validContactNumberRegex.test(passangerContactNumber)) {
      errors.passangerContactNumber =
        "Input contact number with country code eg. +966";
    } else {
    }
  }

  if (validator.isEmpty(passangerAddress)) {
    errors.passangerAddress = "Input your address";
  } else if (passangerAddress === undefined) {
    errors.passangerAddress = "Input your address";
  }

  // if (validator.isEmpty(passangerPassportNumber)) {
  //   errors.passangerPassportNumber = "Input your passport number";
  // } else if (passangerPassportNumber === undefined) {
  //   errors.passangerPassportNumber = "Input your passport number";
  // }

  // if (validator.isEmpty(passangerFlightNumber)) {
  //   errors.passangerFlightNumber = "Input your flight number";
  // } else if (passangerFlightNumber === undefined) {
  //   errors.passangerFlightNumber = "Input your flight number";
  // }

  // if (validator.isEmpty(passangeDepDate)) {
  //   errors.passangeDepDate = 'Input your Dep date';
  // } else if (passangeDepDate === undefined) {
  //   errors.passangeDepDate = 'Input your Dep date';
  // }

  // if (validator.isEmpty(passangeDepTime)) {
  //   errors.passangeDepTime = 'Input your Dep time';
  // } else if (passangeDepTime === undefined) {
  //   errors.passangeDepTime = 'Input your Dep time';
  // }

  return {
    isInvalid: Object.keys(errors).length > 0,
    errors: errors,
    hi: hi,
  };
};

export default validateGeneralDetailsInput;
