<template>
  <div class="helloCircle">
    <p class="mb-0">{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: "SmallCircle",
  props: {
    text: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped>
.helloCircle {
  background: #d1eed2;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.helloCircle p {
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: bold;
}
</style>
