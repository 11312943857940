<template>
  <div id="loading" v-show="springLoader1">
    <DoubleBounce></DoubleBounce>
  </div>
  <div id="generalDivSection" class="d-none">
    <div
      id="generalDSec"
      class="
        row
        d-flex
        justify-content-center
        align-item-center
        mt-2
        ms-0
        me-0
        mt-0
      "
    >
      <SectionHeader :header-title="generalDetails" />
    </div>
    <div id="generalDSecSecond" class="row d-flex justify-content-center pb-1">
      <div class="container-fluid-lg">
        <div class="col-12 container-lg px-lg-5 px-lg-5 m-lg-auto">
          <div class="px-lg-4">
            <div class="d-flex justify-content-center align-item-center">
              <form
                novalidate
                @submit.prevent="addPrimaryPassenger"
                :class="
                  submitted
                    ? 'was-validated col-12 col-sm-11'
                    : 'col-12 col-sm-11'
                "
                id="generalDetailsForm"
                :dir="!isLanguageEN ? 'rtl' : ''"
              >
                <!-- <p
                  class="generalTitle offset-1 fw-bold"
                  v-show="!checkUserLogin"
                >
                  {{ generalDetails.headerSubTitle }}
                </p> -->
                <div v-show="!checkUserLogin">
                  <Avatar />
                </div>
                <div :class="!checkUserLogin ? 'd-block mt-5' : 'd-block'">
                  <div
                    class="
                      row
                      mb-lg-3
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <!--order-5-->
                    <!--                  firstNameDiv-->
                    <div id="firstNameDiv" class="col-lg-1 col-sm-12">
                      <div class="form-group" v-show="!checkUserLogin">
                        <label class="opacity-50 mb-1" for="title">{{
                          generalDetails.title
                        }}</label>
                        <!-- <input
                          id="fName"
                          v-model="formData.passangerFirstName"
                         
                          aria-describedby="fNameError"
                          name="passangerFirstName"
                          required
                          type="text"
                          @keyup="formatFirstName"
                        /> -->
                        <select
                          id="title"
                          :class="`form-control border-1 ${
                            errors.title ? 'is-invalid' : ''
                          }`"
                          v-model="formData.title"
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                        </select>
                      </div>
                    </div>
                    <div id="firstNameDiv" class="col-lg-4 col-sm-12">
                      <div class="form-group" v-show="!checkUserLogin">
                        <label class="opacity-50 mb-1" for="fName">{{
                          generalDetails.firstName
                        }}</label
                        ><label class="text-danger">*</label>
                        <input
                          id="fName"
                          v-model="formData.passangerFirstName"
                          :class="`form-control border-1 ${
                            errors.passangerFirstName ? 'is-invalid' : ''
                          }`"
                          aria-describedby="fNameError"
                          name="passangerFirstName"
                          required
                          type="text"
                          @keyup="formatFirstName"
                        />
                        <small
                          id="fNameError"
                          class="d-block text-danger form-text invalid-feedback"
                          >{{ errors.passangerFirstName }}</small
                        >
                      </div>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <div class="form-group" v-show="!checkUserLogin">
                        <label class="opacity-50 mb-1" for="lName">{{
                          generalDetails.lastName
                        }}</label
                        ><label class="text-danger">*</label>
                        <input
                          id="lName"
                          v-model="formData.passangerLastName"
                          :class="`form-control border-1 ${
                            errors.passangerLastName ? 'is-invalid' : ''
                          }`"
                          aria-describedby="lNameError"
                          type="text"
                          name="passangerLastName"
                          @keyup="formatLastName"
                          required
                        />
                        <small
                          id="lNameError"
                          class="d-block text-danger form-text invalid-feedback"
                          >{{ errors.passangerLastName }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="
                      row
                      mb-lg-3
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <!--                  order-5-->
                    <!--                  emailDiv-->
                    <div id="emailDiv" class="col-lg-5 col-sm-12">
                      <div
                        class="form-group"
                        v-show="!(checkUserLogin || checkPrimaryPassenger)"
                      >
                        <label class="opacity-50 mb-1" for="email">{{
                          generalDetails.email
                        }}</label
                        ><label class="text-danger">*</label>
                        <input
                          id="email"
                          v-model="formData.passangerEmail"
                          :class="`form-control border-1 ${
                            errors.passangerEmail ? 'is-invalid' : ''
                          }`"
                          aria-describedby="emailError"
                          type="Email"
                          name="passangerEmail"
                          @change="checkEmail($event)"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <div
                        class="form-group"
                        v-show="!(checkUserLogin || checkPrimaryPassenger)"
                      >
                        <label class="opacity-50 mb-1" for="phone">{{
                          generalDetails.contact
                        }}</label
                        ><label class="text-danger">*</label>
                        <input
                          id="phone"
                          v-model="formData.passangerContactNumber"
                          :class="`form-control border-1 ${
                            errors.passangerContactNumber ? 'is-invalid' : ''
                          }`"
                          aria-describedby="contactError"
                          type="text"
                          name="passangerContactNumber"
                          @change="checkMobileNumber($event)"
                          required
                          pattern="^\+[1-9]{1}[0-9]{3,14}$"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    :class="
                      !isLanguageEN
                        ? 'row mb-lg-3 d-flex justify-content-center align-items-center'
                        : 'row mb-lg-3 d-flex justify-content-center align-items-center'
                    "
                  >
                    <!-- offset-6-->
                    <!-- addressDivAR-->

                    <div
                      id="addressDivAR"
                      :class="
                        !isLanguageEN
                          ? 'col-lg-5 col-sm-12'
                          : 'col-lg-5 col-sm-12  addressDiv'
                      "
                      v-show="!(checkUserLogin || checkPrimaryPassenger)"
                    >
                      <div class="form-group">
                        <label class="opacity-50 mb-1" for="address">{{
                          generalDetails.address
                        }}</label
                        ><label class="text-danger">*</label>
                        <input
                          id="address"
                          v-model="formData.passangerAddress"
                          :class="`form-control border-1 ${
                            errors.passangerAddress ? 'is-invalid' : ''
                          }`"
                          aria-describedby="addressError"
                          type="text"
                          name="passangerAddress"
                          @keyup="errors.passangerAddress = ''"
                          required
                        />
                        <small
                          id="addressError"
                          class="d-block text-danger form-text invalid-feedback"
                          >{{ errors.passangerAddress }}</small
                        >
                      </div>
                    </div>
                    <div
                      id="passNumberDiv"
                      :class="
                        checkPrimaryPassenger
                          ? !isLanguageEN
                            ? 'col-lg-5 col-sm-12  mb-4 col-lg-5 col-sm-12 addressDiv'
                            : 'col-lg-5 col-sm-12  mb-4 col-lg-5 col-sm-12  addressDiv'
                          : 'col-lg-5 col-sm-12'
                      "
                      v-show="!checkUserLogin"
                    >
                      <div class="form-group">
                        <label class="opacity-50 mb-1" for="passNumber">{{
                          generalDetails.passportNumber
                        }}</label>
                        <input
                          id="passNumber"
                          v-model="formData.passangerPassportNumber"
                          :class="`passport_Input border-1 `"
                          aria-describedby="passNumberError"
                          type="text"
                          name="passangerPassportNumber"
                        />
                      </div>
                    </div>
                    <div
                      class="col-lg-5 col-sm-12"
                      v-if="checkUserLogin || checkPrimaryPassenger"
                    ></div>
                  </div>
                </div>
                <!-- <p
                  class="generalTitle offset-lg-1 mb-1 mt-2 pb-2"
                  v-show="!checkUserLogin"
                >
                  {{ generalDetails.headerSecondSubTitle }}
                </p> -->

                <div
                  :class="
                    isLanguageEN
                      ? ' row offset-lg-1'
                      : ' row  d-flex justify-content-center align-items-center'
                  "
                >
                  <!--                  col-lg-11 pe-lg-3-->
                  <!--                  passportImgDiv-->
                  <div
                    id="passportImgDiv"
                    class="col-lg-5 col-sm-12 ps-lg-0"
                    v-show="!checkUserLogin"
                  >
                    <div class="form-group" id="firstChild">
                      <label class="opacity-50 mb-1" for="passportImg">{{
                        generalDetails.passport_Photo
                      }}</label>

                      <div
                        id="passportPhotoDivMn"
                        class="
                          row
                          passportPhotoDiv
                          form-control
                          border-1
                          d-flex
                          align-items-end
                        "
                        @dragover="changeAppiarance($event)"
                        @dragleave="removeAppiarance($event)"
                        @drop="dropFile($event)"
                      >
                        <div
                          class="
                            col-12
                            d-flex
                            justify-content-center
                            align-items-end
                          "
                        >
                          <img :src="uploadIcon" alt="" class="pt-4" />
                        </div>
                        <div class="col-12">
                          <div
                            style="font-size: 10px; curson: pointer !important"
                            id="passportImg"
                            onfocus="(this.type='file')"
                            :required="requardFile"
                            type="text"
                            :class="`passportImg form-control ${
                              errors.passangerPassportImage ? 'is-invalid' : ''
                            }`"
                            name="passangerPassportImage"
                            aria-describedby="passportPhotoError"
                            @change="displayPassportPhoto($event)"
                          >
                            {{ generalDetails.drag_and_drop_or }}

                            <b class="uploadTextColor">{{
                              generalDetails.browse
                            }}</b>
                            {{ generalDetails.to_upload }}
                          </div>
                          <small
                            id="passportPhotoError"
                            class="
                              me-lg-5
                              mt-lg-3
                              form-text
                              text-muted
                              invalid-feedback
                            "
                            >{{ errors.passangerPassportImage }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="passportImgDiv"
                    class="col-lg-5 col-sm-12 ps-lg-0"
                    v-show="!checkUserLogin"
                  >
                    <div v-show="!springLoader">
                      <Spinner />
                    </div>
                    <img
                      v-show="!checkImage"
                      class="passportImgPreview d-none"
                      id="passportPhoto"
                      :src="
                        checkImage
                          ? formData.passangerPassportImage
                          : formData.passangerPassportImage
                      "
                      height="200"
                    />
                  </div>
                  <div
                    class="
                      col-12 col-sm-11
                      ps-lg-0
                      pe-lg-0
                      mt-lg-4 mt-3
                      d-flex
                      justify-content-center
                    "
                  >
                    <button
                      v-show="!checkUserLogin"
                      id="primaryPassengerButton"
                      class="btn btnAddNew"
                      :disabled="alreadyExistEmail || alreadyExistMobile"
                      @click="
                        (submitted = true), (formData.passangerIsPrimary = 1)
                      "
                    >
                      <img :src="addButton" alt="" class="pt-4" />
                      {{
                        clickedEditButton
                          ? "Update Primary Passenger"
                          : checkSecondaryEdit
                          ? "Update Additional Passenger"
                          : primaryPassengerDetails.length == 0
                          ? generalDetails.addNewPassenger
                          : generalDetails.add_Additional_Passenger
                      }}
                    </button>

                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      v-show="checkUserLogin"
                      id="primaryPassengerButton"
                      class="btn btnAddNew"
                      @click="0()"
                    >
                      <img :src="addButton" alt="" class="pt-4" />
                      {{
                        clickedEditButton
                          ? generalDetails.update_New_Additional_Passenger
                          : generalDetails.add_New_Additional_Passenger
                      }}
                    </button>

                    <button
                      id="btnPrePassenger"
                      type="button"
                      :class="
                        checkUserLogin && isAvailable
                          ? 'btn btnAddNew'
                          : 'btn btnAddPre d-none'
                      "
                      :data-bs-target="
                        checkUserLogin ? `#secondaryUserModal` : ''
                      "
                      :data-bs-toggle="checkUserLogin ? 'modal' : ''"
                      @click="addSecondaryPassenger"
                    >
                      <img :src="addButton" alt="" class="pt-4" />
                      {{
                        checkSecondaryEdit
                          ? "Update Additional Passenger"
                          : generalDetails.addPreviousPassenger
                      }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="px-lg-5">
            <SecondaryUsers />

            <!-- <SecondaryUserForm
              :fNumber="formData.passangerFlightNumber"
              :psNumber="formData.passangerPassportNumber"
              :edit="loginPrimaryPassengerEdit"
              :indexNumber="IndexOfElement"
              :isPrimaryPassenger="isPrimaryPassenger"
            /> -->
            <div
              class="
                px-lg-5
                ms-lg-2
                d-flex
                justify-content-center
                align-item-center
                mt-lg-2 mt-sm-0
              "
            >
              <div class="mt-2 col-12 col-sm-11">
                <div class="row mb-5">
                  <div class="col-lg-6 col-sm-12">
                    <p class="generalTitle mb-2">
                      <small>{{ generalDetails.fistTableCaption }}</small>
                    </p>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <table
                      class="table table-borderless mt-2"
                      :dir="!isLanguageEN ? 'rtl' : ''"
                    >
                      <thead>
                        <tr>
                          <th class="opacity-50" scope="col">
                            {{ generalDetails.firstTableHeader }}
                          </th>
                          <th class="opacity-50" scope="col">
                            {{ generalDetails.secondTableHeader }}
                          </th>
                          <th class="opacity-50" scope="col">
                            {{ generalDetails.thirdTableHeader }}
                          </th>
                          <th class="opacity-50" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            primaryPassenger, index
                          ) of primaryPassengerDetails"
                          :key="index"
                          class="border-bottom"
                        >
                          <td
                            class="
                              col-sm-12
                              d-lg-flex
                              flex-lg-row
                              ps-sm-2 ps-lg-0
                            "
                            data-label="Passenger Name"
                            scope="row"
                          >
                            <div
                              class="
                                col-12 col-lg-6 col-sm-12
                                d-flex
                                align-items-lg-end
                                mt-1
                              "
                            >
                              <p class="mb-1 mb-sm-0 mb-lg-0">
                                {{
                                  primaryPassenger.passangerFirstName +
                                  " " +
                                  primaryPassenger.passangerLastName
                                }}
                              </p>
                            </div>
                            <div
                              class="col-lg-6 col-sm-12 ps-lg-0 ps-sm-3 ps-md-0"
                            >
                              <div class="primary">
                                <p>{{ generalDetails.primary }}</p>
                              </div>
                            </div>
                          </td>
                          <td data-label="Contact">
                            {{ primaryPassenger.passangerContactNumber }}
                          </td>
                          <td data-label="Passport Number">
                            {{ primaryPassenger.passangerPassportNumber }}
                          </td>
                          <td class="text-lg-end">
                            <button
                              class="btn tblBtnEdit"
                              type="button"
                              @click="editePassengerDetails(primaryPassenger)"
                            >
                              <img :src="btnEditButton" alt="" height="13" />
                            </button>
                          </td>
                        </tr>

                        <tr
                          v-for="(secondaryPassengerData, index) of JSON.parse(
                            JSON.stringify(this.secondaryPassengerDetails)
                          )"
                          :key="index"
                          class="border-bottom"
                        >
                          <td data-label="Passenger Name" scope="row">
                            {{
                              secondaryPassengerData.passangerFirstName +
                              " " +
                              secondaryPassengerData.passangerLastName
                            }}
                          </td>
                          <td data-label="Contact">
                            {{ secondaryPassengerData.passangerContactNumber }}
                          </td>
                          <td data-label="Passport Number">
                            {{ secondaryPassengerData.passangerPassportNumber }}
                          </td>
                          <td class="text-lg-end">
                            <button
                              class="btn tblBtnEdit"
                              type="button"
                              @click="
                                editeSecondaryDetails(secondaryPassengerData)
                              "
                            >
                              <img :src="btnEditButton" alt="" height="13" />
                            </button>
                            <button
                              class="btn tblBtnDelete"
                              type="button"
                              @click="
                                deleteSecondaryDetails(
                                  secondaryPassengerData.passengerId
                                )
                              "
                            >
                              <img :src="btnDeleteButton" alt="" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <p class="generalTitle mb-2">
                      <small>{{ generalDetails.secondTableCaption }}</small>
                    </p>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <table class="table table-borderless mt-2">
                      <thead>
                        <tr>
                          <th class="opacity-50" scope="col">
                            {{ generalDetails.firstTableHeader }}
                          </th>
                          <th class="opacity-50" scope="col">
                            {{ generalDetails.secondTableHeader }}
                          </th>
                          <th class="opacity-50" scope="col">
                            {{ generalDetails.thirdTableHeader }}
                          </th>
                          <th class="opacity-50" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>

                      </tbody>
                    </table>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          googleVerifyButton
            ? 'd-flex justify-content-center align-items-center mt-4 d-none'
            : 'd-flex justify-content-center align-items-center mt-4 d-block'
        "
        :dir="!isLanguageEN ? 'rtl' : ''"
      >
        <!-- <ButtonList
          :save="showNextSection"
          :next="generalDetails.nextStep"
          :previous="generalDetails.previous"
          button-name="Next Step"
        /> -->
        <div class="row mt-4 pb-lg-4 mx-0">
          <div
            class="
              col-6
              row
              flex-row
              d-flex
              justify-content-lg-end
              justify-content-md-end
              justify-content-sm-center
              ps-0
              mx-0
            "
          >
            <div
              class="
                form-group
                w-100
                row
                flex-row
                d-flex
                justify-content-lg-end
                justify-content-md-end
                justify-content-sm-center
                mx-0
              "
            >
              <button
                type="button"
                class="btn btn-previous"
                @click="previousView"
              >
                Previous
              </button>
            </div>
          </div>
          <div
            class="
              col-6
              row
              flex-row
              d-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-center
              ps-0 ps-md-3 ps-lg-3
              mx-0
            "
          >
            <div
              class="
                form-group
                w-100
                row
                flex-row
                d-flex
                justify-content-lg-start
                justify-content-md-start
                justify-content-sm-center
                mx-0
              "
            >
              <button
                type="button"
                class="btn btn-next"
                @click="showNextSection"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              this.isPrimaryPassenger
                ? "Edit Passenger Details"
                : "Additional Passenger Details"
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="handleClear()"
          ></button>
        </div>
        <div class="modal-body">
          <form action="" id="additionalPassengers">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.firstName
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="fName"
                type="text"
                class="form-control"
                placeholder="First Name"
                v-model="passangerFirstName"
              />
              <small
                id="fNameError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerFirstName }}</small
              >
            </div>

            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.lastName
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="lName"
                type="text"
                class="form-control"
                placeholder="Last Name"
                v-model="passangerLastName"
              />
              <small
                id="lNameError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerLastName }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.email
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="email"
                type="Email"
                class="form-control"
                placeholder="Email"
                v-model="passangerEmail"
                disabled
                hidden
              />
              <small
                id="emailError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerEmail }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.contact
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="phone"
                type="text"
                class="form-control"
                placeholder="Mobile"
                v-model="passangerContactNumber"
                disabled
                hidden
              />
              <small
                id="contactError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerContactNumber }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.address
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="address"
                type="text"
                class="form-control"
                placeholder="Address"
                v-model="passangerAddress"
                disabled
                hidden
              />
              <small
                id="addressError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerAddress }}</small
              >
            </div>

            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.passportNumber
              }}</label>
              <input
                id="passNumber"
                type="text"
                class="form-control"
                placeholder="Passport Number"
                v-model="passangerPassportNumber"
              />
              <small
                id="passNumberError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerPassportNumber }}</small
              >
            </div>

            <div class="mb-3 d-none">
              <label for="exampleFormControlInput1" class="form-label">{{
                generalDetails.flightNumber
              }}</label
              ><label class="text-danger">*</label>
              <input
                id="flightNumber"
                type="text"
                class="form-control"
                v-model="passangerFlightNumber"
                placeholder="Flight Number"
              />
              <small
                id="flightNumberError"
                class="d-block text-danger form-text invalid-feedback"
                >{{ errors.passangerFlightNumber }}</small
              >
            </div>

            <div class="row">
              <div id="passportImgDiv" class="col-lg-5 col-sm-12 ps-lg-">
                <div class="form-group" id="firstChild">
                  <label class="opacity-50 mb-1" for="passportImg">{{
                    generalDetails.passport_Photo
                  }}</label>

                  <div
                    id="passportPhotoDivMnSecondary"
                    class="
                      row
                      passportPhotoDiv
                      form-control
                      border-1
                      d-flex
                      align-items-end
                    "
                  >
                    <div
                      class="
                        col-12
                        d-flex
                        justify-content-center
                        align-items-end
                      "
                    >
                      <img :src="uploadIcon" alt="" class="pt-4" />
                    </div>
                    <div class="col-12">
                      <div
                        style="font-size: 10px; curson: pointer !important"
                        id="passportImg"
                        onfocus="(this.type='file')"
                        :required="requardFile"
                        type="text"
                        :class="`passportImg form-control ${
                          errors.passangerPassportImage ? 'is-invalid' : ''
                        }`"
                        name="passangerPassportImage"
                        aria-describedby="passportPhotoError"
                      >
                        Drag and Drop or
                        <b class="uploadTextColor">Browse</b> to upload
                      </div>
                      <small
                        id="passportPhotoError"
                        class="
                          me-lg-5
                          mt-lg-3
                          form-text
                          text-muted
                          invalid-feedback
                        "
                        >{{ errors.passangerPassportImage }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="passportImgDiv"
                class="
                  col-lg-5 col-sm-12
                  ps-lg-0
                  d-flex
                  justify-content-center
                  align-item-center
                "
              >
                <div v-show="!springLoader">
                  <Spinner />
                </div>
                <img
                  class="passportImgPreview d-none"
                  id="passportPhotoSecondary"
                  :src="
                    checkImage ? passangerPassportImage : passangerPassportImage
                  "
                  height="200"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="btnCloseModal"
            type="button"
            class="btn btnNo"
            @click="handleClear()"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            id="btnYes"
            type="button"
            class="btn btnYes"
            v-if="isPrimaryPassenger"
            @click="editPrimaryPassenger()"
          >
            Update
          </button>
          <button
            id="btnYes"
            type="button"
            class="btn btnYes"
            v-if="!isPrimaryPassenger"
            @click="addAdditionalSecondaryPassenger()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- -->
</template>
<script>
import vuex from "vuex";
import SectionHeader from "../../../common/components/booking/SectionHeader";
import Avatar from "./Avatar.Vue";
import TelephoneInput from "../../../common/components/booking/TelephoneInput";
import uploadIcon from "../../../assets/Icons/upload.svg";
import addButton from "../../../assets/Icons/addButton.svg";
import addButtonPre from "../../../assets/Icons/addPreButton.svg";
import btnEditButton from "../../../assets/img/pencil-alt-solid.png";
import btnDeleteButton from "../../../assets/Icons/deleteButton.svg";
import validateGeneralDetailsInput from "../../../validations/GeneralDetails/GeneralDetailsValidation";
import SecondaryUsers from "../../../common/components/booking/SecondaryUsers";
import SecondaryUserForm from "../../../common/components/booking/SecondaryUserForm";
import ButtonList from "../../../common/components/booking/ButtonList";
import ButtonNext from "../../../common/components/booking/ButtonNext";
import VueSweetalert2 from "sweetalert2";
import UserService from "../../../services/UserService";
import GeneralDetailsService from "../../../services/GeneralDetailsService";
import BookingService from "../../../services/BookingService";
import GeneralDetails from "../../../models/GeneralDetails";
import Resumable from "resumablejs";
import Spinner from "../../../common/components/Spinner.vue";
import moment from "moment";
import "jquery-clock-timepicker";
import axios from "axios";
import { DoubleBounce } from "vue-loading-spinner";

export default {
  name: "GeneralDetailsSection",
  components: {
    ButtonNext,
    ButtonList,
    SecondaryUsers,
    Avatar,
    SectionHeader,
    TelephoneInput,
    addButton,
    Spinner,
    SecondaryUserForm,
    DoubleBounce,
  },
  data() {
    return {
      Next: "Next Step",
      uploadIcon,
      addButton,
      addButtonPre,
      btnEditButton,
      btnDeleteButton,
      generalDetails: this.$store.getters.getGeneralDetailsLabel,
      formData: new GeneralDetails(),
      loading: false,
      submitted: false,
      primaryPassengerDetails: [],
      secondaryPassengerDetails: [],
      errors: {},
      clickedEditButton: false,
      checkImage: true,
      checkUserLogin: false,
      checkSecondaryEdit: false,
      secPassengerIndex: null,
      imageUrl: "",
      requardFile: false,
      springLoader: false,
      googleVerifyButton: this.$store.getters.getEmailVerificationStatus,
      dropOffFlightNumber: "",
      primaryDetails: [],
      flightNumber:
        this.$store.getters.getDropOffLocationInputDetails.flightNumber,
      checkPrimaryPassenger: false,
      loginPrimaryPassengerEdit: false,
      isPrimaryPassenger: false,
      IndexOfElement: 0,
      isLanguageEN: true,
      alreadyExistEmail: false,
      alreadyExistMobile: false,
      previousPassenerDetails: [],
      isAvailable: false,
      isbuttonDisabled: true,
      springLoader1: false,
      passangerFirstName: "",
      passangerLastName: "",
      passangerEmail: "",
      passangerContactNumber: "",
      passangerAddress: "",
      passangerPassportNumber: "",
      passangerFlightNumber: "",
      passengerId: 0,
      isSecondaryPassenger: false,
      bindProps: {
        mode: "international",
        defaultCountry: "FR",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter a phone number",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ["AU", "BR"],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
        selectedCurrency: "SAR",
        currencyRate: 0,
      },
    };
  },
  mounted() {
    // axios
    //   .get(
    //     "http://login.emerge.lk:9090/MyPorterAPI/api/Passanger/GetSecondryPassangers"
    //   )
    //   .then((response) => {
    //     this.previousPassenerDetails = response.data;
    //     if (response.data.length > 0) {
    //       this.isAvailable = true;
    //     }
    //   });
    const currentCurrencyRate = JSON.parse(localStorage.getItem("CR"));

    if (currentCurrencyRate != null) {
      this.selectedCurrency = currentCurrencyRate.currency;
      this.currencyRate = currentCurrencyRate.currentRate;
    }

    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }

    // this.initResumable('passportImg');
    this.initResumable("passportPhotoDivMn");
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser) {
      this.checkUserLogin = true;
    } else {
      this.checkUserLogin = false;
    }

    if (this.checkUserLogin) {
      this.getPrimaryPassenger(currentUser.email);
    }
    this.secondaryPassengerDetails = this.getSecondaryPassengerDetails;

    if (this.primaryPassengerDetails.length > 0) {
      this.primaryPassengerDetails = this.getPrimaryPassengerDetails;
      document
        .getElementById("pickUpLocationSectionDiv")
        .classList.remove("d-none");
      document
        .getElementById("pickUpLocationSectionDiv")
        .classList.add("d-block");
    }
    if (this.secondaryPassengerDetails) {
      this.secondaryPassengerDetails = this.getSecondaryPassengerDetails;
    }

    if (this.checkUserLogin) {
      UserService.getUserDetails()
        .then((response) => {
          this.primaryDetails = response.data ? response.data : [];
        })
        .catch((error) => {});
    }
  },

  computed: {
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapGetters(["getSecondaryPassengerDetails"]),
    ...vuex.mapGetters(["getEmailVerificationStatus"]),
    ...vuex.mapGetters(["getDropOffLocationInputDetails"]),
    ...vuex.mapGetters(["getLuggageImageDetails"]),
  },

  created() {
    this.secondaryPassengerDetails =
      this.$store.getters.getSecondaryPassengerDetails;
    this.primaryPassengerDetails =
      this.$store.getters.getPrimaryPassengerDetails;
    this.secondaryPassengerDetails =
      this.$store.getters.getSecondaryPassengerDetails;
  },
  watch: {
    // getPrimaryPassengerDetails() {
    //   if (!this.checkUserLogin) {
    //     this.primaryPassengerDetails = this.getPrimaryPassengerDetails;
    //   }
    // },
    getSecondaryPassengerDetails() {
      this.secondaryPassengerDetails = this.getSecondaryPassengerDetails;
    },

    getEmailVerificationStatus() {
      this.googleVerifyButton = this.$store.getters.getEmailVerificationStatus;
    },

    getDropOffLocationInputDetails() {
      this.dropOffFlightNumber =
        this.getDropOffLocationInputDetails.flightNumber;
    },
  },

  methods: {
    ...vuex.mapActions(["setGeneralDetails"]),
    ...vuex.mapActions(["setUpdateGeneralDetails"]),
    ...vuex.mapActions(["setSecondaryDetails"]),
    ...vuex.mapActions(["setUpdateSecondaryDetails"]),
    ...vuex.mapActions(["deleteSecondaryPassenger"]),
    ...vuex.mapGetters(["getSecondaryPassengerDetails"]),

    ...vuex.mapGetters(["pickupLocation"]),
    ...vuex.mapGetters(["getDropOffLocationInputDetails"]),
    ...vuex.mapGetters(["getLuggageImageDetails"]),
    ...vuex.mapGetters(["getPrimaryPassengerDetails"]),
    ...vuex.mapGetters(["getVerifyLuggageImage"]),
    ...vuex.mapActions(["checkEmailVerification"]),
    ...vuex.mapActions(["setVerifyLuggageImage"]),
    ...vuex.mapActions(["setConfirmUserDetails"]),

    handleClear() {
      // this.passangerFirstName = "";
      // this.passangerLastName = "";
      // this.passangerEmail = "";
      // this.passangerContactNumber = "";
      // this.passangerPassportNumber = "";
      // this.passengerId = 0;
      // this.isSecondaryPassenger = false;
      this.isPrimaryPassenger = false;
    },
    previousView() {
      document.getElementById("generalDivSection").classList.remove("d-block");
      document.getElementById("generalDivSection").classList.add("d-none");
      this.springLoader1 = true;
      setTimeout(() => {
        this.springLoader1 = false;
        document.getElementById("verificationSec").classList.add("d-block");
        document.getElementById("verificationSec").classList.remove("d-none");
      }, 800);
    },
    initResumable: function (element) {
      let portalid = 1;
      let projectid = 1;
      let dnnInstallationpath = "";
      // setTimeout(() => {
      //   const container = this.$el.querySelector('passportImg');
      //   container.scrollTop = container.scrollHeight;
      // }, 3000);
      let uploaderimage = document.getElementById(element);

      let actions = this;

      actions.springLoader = true;

      // let uploaderimage = document.querySelector('passportImage01')
      let imagetype = ["bmp", "gif", "png", "jpeg", "JPG"];
      //this.resumable(imagetype, 'http://localhost:61709/api/FileUpload/upload', portalid, uploaderimage, 2, projectid,dnnInstallationpath)
      this.resumable(
        imagetype,
        "http://login.emerge.lk:9090/MyPorterFileUploaderAPI/api/FileUploader/Upload",
        portalid,
        uploaderimage,
        2,
        projectid,
        dnnInstallationpath
      );
    },

    resumable(
      filetype,
      target,
      portalid,
      element,
      type,
      projectid,
      dnnInstallationpath
    ) {
      let self = this;
      let resumableObject = {
        target: target,
        query: {
          PortalId: portalid,
          ProjectId: projectid,
          dnnInstallationPath: dnnInstallationpath,
        },
        simultaneousUploads: 10,
        fileType: filetype,
        maxFiles: 10,
      };

      let r = new Resumable(resumableObject);
      r.assignBrowse(element);
      r.on("progress", function () {});

      r.on("fileAdded", function (file) {
        var filesize = Math.round(file.size / 1048576);
        if (filesize < 71) {
          self.isUploading = true;
          self.isPosted = true;
          if (type == 3) {
            r.upload();
            // if(gtDocsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 1) {
            r.upload();
            //  if(gtVideoValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 2) {
            r.upload();
            // if(gtGraphicsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          }
        } else {
          //  self.clearFileUplloadStuff();
          //  self.eventBus.$emit('showMessage', 'File is too large. Please upload a file which is less than 70MB', 'warn')
        }
      });

      r.on("fileSuccess", function (file, message) {
        // var tempFileName = file.fileName;
        // const imgHello = tempFileName;

        self.imageUrl = message;

        self.springLoader = false;

        setTimeout(() => {
          self.springLoader = true;
          document.getElementById("passportPhoto").src = message;
          document.getElementById("passportPhoto").classList.remove("d-none");
          document.getElementById("passportPhoto").classList.add("d-block");
        }, 3000);
      });

      r.on("fileError", function (file) {
        this.imageUrl = "http://myporterqa.emerge.lk/img/bg/contact.png";

        //  self.clearFileUplloadStuff();
        // self.eventBus.$emit('showMessage', 'An error occurred', 'warn')
      });
    },
    getPrimaryPassenger(email) {
      GeneralDetailsService.getPrimaryPassenger(email).then((response) => {
        if (response) {
          var data = {
            passengerId: response.data.pasangerID,
            passangerFirstName: response.data.passangerFirstName,
            passangerLastName: response.data.passangerLastName,
            passangerEmail: response.data.passangerEmail,
            passangerContactNumber: response.data.passangerContactNumber,
            passangerAddress: response.data.passangerAddress,
            passangerPassportNumber: response.data.passangerPassportNumber,
            passengerIsPrimary: true,
          };
          this.primaryPassengerDetails.push(data);
        }
      });
      this.primaryPassengerDetails = this.primaryPassengerDetails.filter(
        (item, index) =>
          item.passengerId !== this.primaryPassengerDetails[0].passengerId
      );
    },

    setDefaultDate(event) {
      if (event.target.value == "") {
        this.formData.passangeDepDate = new Date().toISOString().split("T")[0];
      }

      // document.getElementById('date').value = new Date()
      //   .toISOString()
      //   .split('T')[0];

      this.errors.passangeDepDate = "";
    },

    checkMobileNumber(event) {
      this.loading = true;
      this.errors.passangerContactNumber = "";
      if (event.target.value) {
        if (isNaN(event.target.value)) {
          this.errors.passangerContactNumber = "Input a valid mobile number.";
        } else {
          const validContactNumberRegex = RegExp(/^(\+\d{1,9}[- ]?)?\d{10}$/);
          if (!validContactNumberRegex.test(event.target.value)) {
            this.errors.passangerContactNumber =
              "Input contact number with country code eg. +966.";
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "warning",
              title: "Input contact number with country code eg. +966.",
            });
          } else {
            const passengerEmail = document.getElementById("email").value;
            const data = {
              customerEmail: "",
              customerNumber: event.target.value,
            };

            GeneralDetailsService.checkEmailAndMobileConfirmation(data).then(
              (response) => {
                if (!(response.data === "Success")) {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: "Mobile number already exists.",
                  });
                  this.loading = true;
                  this.alreadyExistMobile = true;
                } else {
                  this.alreadyExistMobile = false;
                }
              }
            );
          }
        }
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: "Input a valid mobile number.",
        });
      }
    },

    checkEmail(event) {
      this.errors.passangerEmail = "";

      if (event.target.value) {
        const data = {
          customerEmail: event.target.value,
          customerNumber: "",
        };

        GeneralDetailsService.checkEmailAndMobileConfirmation(data).then(
          (response) => {
            if (!(response.data === "Success")) {
              // this.errors.passangerEmail = response.data;
              // VueSweetalert2.fire({
              //   toast: true,
              //   position: "top-end",
              //   showConfirmButton: false,
              //   timer: 3000,
              //   icon: "warning",
              //   title: "Email already exists",
              // });

              VueSweetalert2.fire({
                title:
                  "This email address is already in use. To continue with this process, you must first log into the system.",
                text: "Do you want to login to the system?",
                showCancelButton: true,
                confirmButtonColor: "#006203",
                cancelButtonColor: "#db0e03",
                confirmButtonText: "Login",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.loading = true;

                  window.location.href = "/login";
                }
              });
              this.loading = true;
              this.alreadyExistEmail = true;
            } else {
              this.alreadyExistEmail = false;
              if (this.alreadyExistEmail || this.alreadyExistMobile) {
                this.loading = false;
              }
            }
          }
        );
      }
    },

    dropFile(event) {
      const resumableJsFunction = this;

      event.preventDefault();
      const passportImgFileData = event.dataTransfer.files[0];

      let checked = true;
      setTimeout(function () {
        const reader = new FileReader();
        reader.onload = function (e) {
          checked = true;
          document.getElementById("passportPhoto").src = e.target.result;
          resumableJsFunction.formData.passangerPassportImage = e.target.result;
          resumableJsFunction.initResumable("passportPhoto");
          document.getElementById("passportPhoto").classList.remove("d-none");
          document.getElementById("passportPhoto").classList.add("d-block");
          resumableJsFunction.checkImage = checked;
        };
        reader.readAsDataURL(passportImgFileData);
      }, 1000);
      if (checked) {
        this.checkImage = true;
      } else {
        this.checkImage = false;
      }
    },

    removeAppiarance(event) {
      document
        .getElementById("passportPhotoDivMn")
        .classList.remove("changeBackground");
    },

    addNewAddtitonalPassenger() {
      this.loginPrimaryPassengerEdit = false;
    },
    changeAppiarance(event) {
      event.preventDefault();
      document
        .getElementById("passportPhotoDivMn")
        .classList.add("changeBackground");
    },

    deleteSecondaryDetails(id) {
      // this.secondaryPassengerDetails.splice(index, 1);
      // this.deleteSecondaryPassenger(index);
      // this.secondaryPassengerDetails = this.getSecondaryPassengerDetails;
      this.secondaryPassengerDetails = this.secondaryPassengerDetails.filter(
        (item) => item.passengerId !== id
      );
    },

    addAdditionalSecondaryPassenger() {
      if (this.passangerFirstName !== "" && this.passangerLastName !== "") {
        var isExist = this.primaryPassengerDetails.some(
          (item) =>
            item.passangerFirstName == this.passangerFirstName &&
            item.passangerLastName == this.passangerLastName
        );
        var isExistSecondaryPassenger = this.secondaryPassengerDetails.some(
          (item) =>
            item.passangerFirstName == this.passangerFirstName &&
            item.passangerLastName == this.passangerLastName
        );
        if (!isExist && !isExistSecondaryPassenger) {
          var obj = {
            passengerId: Math.floor(Math.random() * Date.now()),
            passangerFirstName: this.passangerFirstName,
            passangerLastName: this.passangerLastName,
            passangerEmail: "",
            passangerContactNumber: "",
            passangerAddress: "",
            passangerPassportNumber: this.passangerPassportNumber,
            passengerIsPrimary: false,
          };
          this.secondaryPassengerDetails.push(obj);

          this.passengerId = "";
          this.passangerFirstName = "";
          this.passangerLastName = "";

          this.passangerPassportNumber = "";
          // $("#exampleModal").modal("hide");
          let modalClose = document.getElementById("btnCloseModal");
          modalClose.click();
        } else {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "This user already exists in the table.",
          });
        }
      } else {
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: "Please fill out the required fields.",
        });
      }
    },
    editPrimaryPassenger() {
      if (!this.isSecondaryPassenger) {
        if (this.passengerId > 0) {
          VueSweetalert2.fire({
            title: "Are you sure?",
            text: "You want to update your details!",
            showCancelButton: true,
            confirmButtonColor: "#006203",
            cancelButtonColor: "#F05C54",
            confirmButtonText: "Yes, Update it!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.primaryPassengerDetails = this.primaryPassengerDetails.map(
                (item, index) => ({
                  ...item,
                  passangerFirstName:
                    item.passengerId == this.passengerId
                      ? this.passangerFirstName
                      : item.passangerFirstName,
                  passangerLastName:
                    item.passengerId == this.passengerId
                      ? this.passangerLastName
                      : item.passangerLastName,
                  passangerPassportNumber:
                    item.passengerId == this.passengerId
                      ? this.passangerPassportNumber
                      : item.passengerPassportNumber,
                })
              );
              this.isPrimaryPassenger = false;

              this.passengerId = "";
              this.passangerFirstName = "";
              this.passangerLastName = "";

              this.passangerPassportNumber = "";
            }
          });
        }
      } else {
        if (this.passengerId > 0) {
          VueSweetalert2.fire({
            title: "Are you sure?",
            text: "You want to update your details!",
            showCancelButton: true,
            confirmButtonColor: "#006203",
            cancelButtonColor: "#F05C54",
            confirmButtonText: "Yes, Update it!",
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(
                "secondaryPassengerDetails",
                this.secondaryPassengerDetails
              );
              console.log("this.passengerId", this.passengerId);
              console.log("this.passangerFirstName", this.passangerFirstName);
              console.log("this.passangerLastName", this.passangerLastName);
              console.log("this.passangerEmail", this.passangerEmail);
              console.log(
                "this.passangerContactNumber",
                this.passangerContactNumber
              );
              console.log(
                "this.passangerPassportNumber",
                this.passangerPassportNumber
              );

              this.secondaryPassengerDetails =
                this.secondaryPassengerDetails.map((item, index) => ({
                  ...item,
                  passangerFirstName:
                    item.passengerId == this.passengerId
                      ? this.passangerFirstName
                      : item.passangerFirstName,
                  passangerLastName:
                    item.passengerId == this.passengerId
                      ? this.passangerLastName
                      : item.passangerLastName,
                  passangerPassportNumber:
                    item.passengerId == this.passengerId
                      ? this.passangerPassportNumber
                      : item.passangerPassportNumber,
                }));
              console.log(
                "secondaryPassengerDetails",
                this.secondaryPassengerDetails
              );
              this.isPrimaryPassenger = false;

              this.passengerId = "";
              this.passangerFirstName = "";
              this.passangerLastName = "";
              this.passangerPassportNumber = "";
            }
          });
          this.isSecondaryPassenger = false;
        }
      }

      // $("#exampleModal").modal("hide");
      let modalClose = document.getElementById("btnCloseModal");
      modalClose.click();
    },
    editePassengerDetails(item) {
      if (this.checkUserLogin) {
        // this.loginPrimaryPassengerEdit = true;
        this.isPrimaryPassenger = true;
        // this.IndexOfElement = index;

        $("#exampleModal").modal("show");
        this.passengerId = item.passengerId;
        this.passangerFirstName = item.passangerFirstName;
        this.passangerLastName = item.passangerLastName;

        this.passangerPassportNumber = item.passangerPassportNumber;
        // if (index == 0) {
        //   this.$store.dispatch("setPrimaryEdit", true);
        // }
        // var primaryPassenger = JSON.stringify(this.primaryPassengerDetails);
        // sessionStorage.setItem("primaryPassenger", primaryPassenger);
        // sessionStorage.setItem("isPrimaryPassenger", true);
        // sessionStorage.setItem("lang", "EN");

        // this.setGeneralDetails(this.primaryPassengerDetails);
      } else {
        this.clickedEditButton = true;
        this.checkPrimaryPassenger = false;

        this.loading = false;

        this.formData.passangerFirstName =
          this.primaryPassengerDetails[0].passangerFirstName;
        this.formData.passangerLastName =
          this.primaryPassengerDetails[0].passangerLastName;
        this.formData.passangerEmail =
          this.primaryPassengerDetails[0].passangerEmail;
        this.formData.passangerContactNumber =
          this.primaryPassengerDetails[0].passangerContactNumber;
        this.formData.passangerAddress =
          this.primaryPassengerDetails[0].passangerAddress;
        this.formData.passangerPassportNumber =
          this.primaryPassengerDetails[0].passangerPassportNumber;
        this.formData.passangerFlightNumber =
          this.primaryPassengerDetails[0].passangerFlightNumber;
        this.formData.passangeDepDate =
          this.primaryPassengerDetails[0].passangeDepDate;
        this.formData.passangeDepTime =
          this.primaryPassengerDetails[0].passangeDepTime;

        setTimeout(() => {
          if (
            this.primaryPassengerDetails[0].passangerPassportImage === "" ||
            this.primaryPassengerDetails[0].passangerPassportImage === undefined
          ) {
            document.getElementById("passportPhoto").classList.add = "d-none";
          } else {
            document.getElementById("passportPhoto").src =
              this.primaryPassengerDetails[0].passangerPassportImage;
          }

          document.getElementById("profileImage").src =
            this.primaryPassengerDetails[0].passengerProfile;

          document.getElementById("passportPhoto").classList.remove("d-none");
          document.getElementById("passportPhoto").classList.add("d-block");
        }, 500);
      }
    },

    editeSecondaryDetails(data) {
      if (this.checkUserLogin) {
        // this.loginPrimaryPassengerEdit = true;
        // this.IndexOfElement = index;
        // this.$store.dispatch("setPrimaryEdit", false);

        this.isPrimaryPassenger = true;
        this.isSecondaryPassenger = true;
        $("#exampleModal").modal("show");
        this.passengerId = data.passengerId;
        this.passangerFirstName = data.passangerFirstName;
        this.passangerLastName = data.passangerLastName;

        this.passangerPassportNumber = data.passangerPassportNumber;
      } else {
        // document.getElementById("primaryPassengerButton").innerText =
        //   "Update Secondary Passenger";

        document.getElementById("primaryPassengerButton").disabled = false;

        this.checkSecondaryEdit = true;

        this.passengerId = data.passangerID;

        // this.secPassengerIndex = index;

        this.loading = false;

        this.formData = new GeneralDetails(
          data.title,
          data.passangerID,
          data.passengerProfile,
          data.passangerFirstName,
          data.passangerLastName,
          data.passangerEmail,
          data.passangerContactNumber,
          data.passangerAddress,
          data.passangerPassportNumber,
          "",
          data.passangerIsPrimary,
          data.passangerFlightNumber,
          data.passangeDepDate,
          data.passangeDepTime,
          data.isActive
        );
        // setTimeout(() => {
        //   document.getElementById("profileImage").src = data.passengerProfile;
        //   document.getElementById("passportPhoto").src =
        //     tdata.passangerPassportImage;
        //   if (
        //     data.passangerPassportImage === "" ||
        //     data.passangerPassportImage === undefined
        //   ) {
        //     document
        //       .getElementById("passportPhoto")
        //       .classList.remove("d-block");
        //     document.getElementById("passportPhoto").classList.add("d-none");
        //   } else {
        //     document.getElementById("passportPhoto").classList.remove("d-none");
        //     document.getElementById("passportPhoto").classList.add("d-block");
        //   }
        // }, 500);
      }
    },

    formatFirstName() {
      this.errors.passangerFirstName = "";
      if (this.pFirstName) {
        this.pFirstName = this.pFirstName.toString();
        this.pFirstName =
          this.pFirstName.charAt(0).toUpperCase() + this.pFirstName.slice(1);
      }
    },

    formatLastName() {
      this.errors.passangerLastName = "";
      if (this.pLastName) {
        this.pLastName = this.pLastName.toString();
        this.pLastName =
          this.pLastName.charAt(0).toUpperCase() + this.pLastName.slice(1);
      }
    },

    showNextSection() {
      if (this.checkUserLogin) {
        if (this.isbuttonDisabled === true) {
          this.isbuttonDisabled = false;
          this.loadBookingSummary();
        }
        document.getElementById("generalDivSection").classList.add("d-none");
        document
          .getElementById("generalDivSection")
          .classList.remove("d-block");
        this.springLoader1 = true;
        setTimeout(() => {
          this.springLoader1 = false;
          window.location.href = "#confirmationMainSec";
        }, 500);
      } else {
        if (this.primaryPassengerDetails.length === 0) {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Please add at least one passenger.",
            icon: "error",
          });

          // document.getElementById("generalDivSection").classList.add("d-none");
          // document
          //   .getElementById("generalDivSection")
          //   .classList.remove("d-block");
          // this.springLoader1 = true;
          // setTimeout(() => {
          //   this.springLoader1 = false;
          //   document
          //     .getElementById("confirmationMainSec")
          //     .classList.remove("d-none");
          //   document
          //     .getElementById("confirmationMainSec")
          //     .classList.add("d-block");
          // }, 800);
          return;
        } else {
          document.getElementById("generalDivSection").classList.add("d-none");
          document
            .getElementById("generalDivSection")
            .classList.remove("d-block");
          this.springLoader1 = true;
          setTimeout(() => {
            this.springLoader1 = false;
            document
              .getElementById("confirmationMainSec")
              .classList.remove("d-none");
            document
              .getElementById("confirmationMainSec")
              .classList.add("d-block");
          }, 800);
        }
      }
    },

    displayPassportPhoto(event) {
      let checked = true;
      this.checkImage = checked;
      // }, 2000);
      if (checked) {
        this.checkImage = true;
      } else {
        this.checkImage = false;
      }
    },

    //add primary passenger to localStorage
    addPrimaryPassenger() {
      if (this.checkUserLogin) {
        this.addSecondaryPassenger();
      } else {
        if (
          this.primaryPassengerDetails.length > 0 &&
          !this.clickedEditButton
        ) {
          this.addSecondaryPassenger();
        } else {
          this.loading = false;
          const localPath = window.location.href;
          const localProfile = localPath + "img/avetar.142bfcda.svg";
          const fName = document.getElementById("fName");
          const lName = document.getElementById("lName");
          const pEmail = document.getElementById("email");
          const phone = document.getElementById("phone");
          const address = document.getElementById("address");
          const passNumber = document.getElementById("passNumber");
          const flightNumber = document.getElementById("flightNumber");
          const date = document.getElementById("date");
          const time = document.getElementById("time");

          this.formData.passangerFlightNumber =
            this.$store.getters.getDropOffLocationInputDetails.flightNumber;

          if (this.clickedEditButton) {
            this.updatePrimaryPassenger();
          } else if (this.checkSecondaryEdit) {
            this.updateSecondaryPassenger();
          } else {
            const profileImg = document.getElementById("profileImage").src;
            const pPassportPhoto = document.getElementById("passportPhoto").src;

            if (profileImg === localProfile) {
              this.loading = false;
              VueSweetalert2.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: "Please upload your profile image.",
              });
              return;
            }
            //  else if (pPassportPhoto === "") {
            //   this.loading = false;
            //   VueSweetalert2.fire({
            //     toast: true,
            //     position: "top-end",
            //     showConfirmButton: false,
            //     timer: 3000,
            //     icon: "error",
            //     title: "Please upload passport image.!",
            //   });
            //   return;
            // }
            else {
              const { isInvalid, errors } = validateGeneralDetailsInput(
                this.formData
              );

              if (isInvalid) {
                this.errors = errors;

                this.errors.passangerFirstName ? fName.focus() : "";
                this.errors.passangerLastName ? lName.focus() : "";
                this.errors.passangerEmail ? pEmail.focus() : "";
                this.errors.passangerContactNumber ? phone.focus() : "";
                this.errors.passangerAddress ? address.focus() : "";
                // this.errors.passangerPassportNumber ? passNumber.focus() : "";
                // this.errors.passangerFlightNumber ? flightNumber.focus() : "";
                this.errors.passangeDepDate ? date.focus() : "";
                this.errors.passangeDepTime ? time.focus() : "";

                if (
                  this.errors.passangerEmail &&
                  this.errors.passangerContactNumber
                ) {
                  VueSweetalert2.fire({
                    toast: false,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: `${errors.passangerContactNumber} \n ${this.errors.passangerEmail}.`,
                  });
                } else if (this.errors.passangerEmail) {
                  VueSweetalert2.fire({
                    toast: false,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: `${this.errors.passangerEmail}.`,
                  });
                } else if (this.errors.passangerContactNumber) {
                  VueSweetalert2.fire({
                    toast: false,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: `${this.errors.passangerContactNumber}.`,
                  });
                }

                setTimeout(() => {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: "Please enter your details.",
                  });
                }, 1500);

                this.loading = false;
                return;
              } else {
                this.errors = {};
                try {
                  if (this.primaryPassengerDetails.length === 0) {
                    this.formData.passengerProfile = profileImg;
                    this.formData.passangerPassportImage = this.imageUrl;

                    this.setGeneralDetails(this.formData);

                    this.primaryPassengerDetails =
                      this.getPrimaryPassengerDetails;

                    this.requardFile = false;

                    this.checkPrimaryPassenger = true;

                    this.clearTextFields();
                    document
                      .getElementById("passportPhoto")
                      .removeAttribute("src");
                    document
                      .getElementById("passportPhoto")
                      .classList.remove("d-block");
                    document
                      .getElementById("passportPhoto")
                      .classList.add("d-none");
                    document.getElementById("generalDetailsForm").reset();
                  } else {
                    VueSweetalert2.fire({
                      toast: true,
                      position: "bottom-end",
                      showConfirmButton: false,
                      timer: 3000,
                      title: "You cant add second primary passenger.",
                      icon: "error",
                    });
                  }
                } catch (e) {}
              }
            }
          }
        }
      }
    },

    updatePrimaryPassenger() {
      const fName = document.getElementById("fName");
      const lName = document.getElementById("lName");
      const pEmail = document.getElementById("email");
      const phone = document.getElementById("phone");
      const address = document.getElementById("address");
      const passNumber = document.getElementById("passNumber");
      const flightNumber = document.getElementById("flightNumber");
      const date = document.getElementById("date");
      const time = document.getElementById("time");

      this.formData.passangerFlightNumber =
        this.$store.getters.getDropOffLocationInputDetails.flightNumber;

      if (this.clickedEditButton) {
        VueSweetalert2.fire({
          title: "Are you sure?",
          text: "You want to update your details!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            const localPath = window.location.href;
            const localProfile = localPath + "img/avetar.142bfcda.svg";
            if (
              this.formData.passangerFirstName &&
              this.formData.passangerLastName &&
              this.formData.passangerEmail &&
              this.formData.passangerContactNumber &&
              this.formData.passangerAddress &&
              // this.formData.passangerPassportNumber &&
              this.formData.passangerIsPrimary &&
              this.formData.passangerFlightNumber &&
              this.formData.passangeDepDate &&
              this.formData.passangeDepTime
            ) {
              const profileImg = document.getElementById("profileImage").src;
              const pPassportPhoto =
                document.getElementById("passportPhoto").src;

              // if (profileImg === localProfile) {
              //   this.loading = false;
              //   VueSweetalert2.fire({
              //     toast: true,
              //     position: 'top-end',
              //     showConfirmButton: false,
              //     timer: 3000,
              //     icon: 'error',
              //     title: 'Please upload your profile image.!',
              //   });

              //   this.loading = false;
              //   return;
              // } else if (pPassportPhoto === '') {
              //   this.loading = false;
              //   VueSweetalert2.fire({
              //     toast: true,
              //     position: 'top-end',
              //     showConfirmButton: false,
              //     timer: 3000,
              //     icon: 'error',
              //     title: 'Please upload passport image.!',
              //   });

              //   this.loading = false;
              //   return;
              // } else {

              const { isInvalid, errors } = validateGeneralDetailsInput(
                this.formData
              );
              if (isInvalid) {
                this.errors = errors;
                this.errors.passangerFirstName ? fName.focus() : "";
                this.errors.passangerLastName ? lName.focus() : "";
                this.errors.passangerEmail ? pEmail.focus() : "";
                this.errors.passangerContactNumber ? phone.focus() : "";
                this.errors.passangerAddress ? address.focus() : "";
                // this.errors.passangerPassportNumber ? passNumber.focus() : "";
                // this.errors.passangerFlightNumber ? flightNumber.focus() : "";
                this.errors.passangeDepDate ? date.focus() : "";
                this.errors.passangeDepTime ? time.focus() : "";
                this.loading = false;
              } else {
                this.errors = {};
                try {
                  if (this.primaryPassengerDetails.length != 0) {
                    this.formData.passengerProfile = profileImg;
                    this.formData.passangerPassportImage = this.imageUrl;

                    this.setUpdateGeneralDetails(this.formData);

                    this.primaryPassengerDetails =
                      this.getPrimaryPassengerDetails;

                    this.checkPrimaryPassenger = true;
                    this.clickedEditButton = false;
                    this.clearTextFields();

                    document
                      .getElementById("passportPhoto")
                      .removeAttribute("src");
                    document
                      .getElementById("passportPhoto")
                      .classList.remove("d-block");
                    document
                      .getElementById("passportPhoto")
                      .classList.add("d-none");

                    // document.getElementById(
                    //   "primaryPassengerButton"
                    // ).innerText = "Add New Passenger";

                    if (this.checkUserLogin) {
                      setTimeout(() => {
                        window.location.href = "#confirmationMainSec";
                      }, 500);
                    }

                    document.getElementById("generalDetailsForm").reset();
                  } else {
                    VueSweetalert2.fire({
                      toast: true,
                      position: "bottom-end",
                      showConfirmButton: false,
                      timer: 3000,
                      title: "You cant add second primary passenger.",
                      icon: "error",
                    });
                  }
                } catch (e) {}
              }
              // }
            } else {
              this.loading = false;
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: "Please enter your details.",
              });

              this.pDep_Time ? "" : time.focus();
              this.pDep_Date ? "" : date.focus();
              // this.pFlightNumber ? "" : flightNumber.focus();
              // this.pPassportNumber ? "" : passNumber.focus();
              this.pAddress ? "" : address.focus();
              this.pContact ? "" : phone.focus();
              this.pEmail ? "" : pEmail.focus();
              this.pLastName ? "" : lName.focus();
              this.pFirstName ? "" : fName.focus();
            }
          }
        });
      } else {
      }
    },

    addSecondaryPassenger() {
      this.requardFile = false;

      if (!this.checkUserLogin) {
        this.loading = true;
        const localPath = window.location.href;
        const localProfile = localPath + "img/avetar.142bfcda.svg";
        const fName = document.getElementById("fName");
        const lName = document.getElementById("lName");
        const pEmail = document.getElementById("email");
        const phone = document.getElementById("phone");
        const address = document.getElementById("address");
        const passNumber = document.getElementById("passNumber");
        const flightNumber = document.getElementById("flightNumber");
        const date = document.getElementById("date");
        const time = document.getElementById("time");
        // console.log("this.formData", this.formData);
        this.formData.passangerFlightNumber =
          this.$store.getters.getDropOffLocationInputDetails.flightNumber;
        // this.formData.passangerID = 0;
        this.formData.passangerEmail =
          this.primaryPassengerDetails[0].passangerEmail;

        this.formData.passangerAddress =
          this.primaryPassengerDetails[0].passangerAddress;

        this.formData.passangerContactNumber =
          this.primaryPassengerDetails[0].passangerContactNumber;

        if (
          this.formData.passangerFirstName &&
          this.formData.passangerLastName &&
          this.formData.passangerEmail &&
          this.formData.passangerContactNumber &&
          this.formData.passangerAddress &&
          // this.formData.passangerPassportNumber &&
          this.formData.passangerFlightNumber &&
          this.formData.passangeDepDate &&
          this.formData.passangeDepTime
        ) {
          if (this.checkSecondaryEdit) {
            this.updateSecondaryPassenger();
          } else {
            const profileImg = document.getElementById("profileImage").src;
            if (profileImg === localProfile) {
              this.loading = false;
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: "Please upload your profile image.",
              });
              return;
            } else {
              const { isInvalid, errors } = validateGeneralDetailsInput(
                this.formData
              );
              if (isInvalid) {
                this.errors = errors;
                this.errors.passangerFirstName ? fName.focus() : "";
                this.errors.passangerLastName ? lName.focus() : "";
                this.errors.passangerEmail ? pEmail.focus() : "";
                this.errors.passangerContactNumber ? phone.focus() : "";
                this.errors.passangerAddress ? address.focus() : "";
                // this.errors.passangerPassportNumber ? passNumber.focus() : "";
                // this.errors.passangerFlightNumber ? flightNumber.focus() : "";
                this.errors.passangeDepDate ? date.focus() : "";
                this.errors.passangeDepTime ? time.focus() : "";

                if (
                  this.errors.passangerEmail &&
                  this.errors.passangerContactNumber
                ) {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: `${errors.passangerContactNumber} \n ${this.errors.passangerEmail}.`,
                  });
                } else if (this.errors.passangerEmail) {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: `${this.errors.passangerEmail}.`,
                  });
                } else if (this.errors.passangerContactNumber) {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "warning",
                    title: `${this.errors.passangerContactNumber}.`,
                  });
                }

                setTimeout(() => {
                  VueSweetalert2.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: "Please enter your details.",
                  });
                }, 1500);

                this.loading = false;
                return;
              } else {
                this.errors = {};
                try {
                  this.formData.passengerProfile = profileImg;
                  if (this.imageUrl === undefined) {
                    this.formData.passangerPassportImage = "";
                  } else {
                    this.formData.passangerPassportImage =
                      document.getElementById("passportPhoto").src;
                  }
                  var obj = {
                    passangerID: Math.floor(Math.random() * Date.now()),
                    passengerProfile: this.formData.passengerProfile,
                    passangerFirstName: this.formData.passangerFirstName,
                    passangerLastName: this.formData.passangerLastName,
                    passangerEmail: this.formData.passangerEmail,
                    passangerContactNumber:
                      this.formData.passangerContactNumber,
                    passangerAddress: this.formData.passangerAddress,
                    passangerPassportNumber:
                      this.formData.passangerPassportNumber,
                    passangerPassportImage:
                      this.formData.passangerPassportImage,
                    passangerIsPrimary: this.formData.passangerIsPrimary,
                    passangerFlightNumber: this.formData.passangerFlightNumber,
                    passangeDepDate: this.formData.passangeDepDate,
                    passangeDepTime: this.formData.passangeDepTime,
                    isActive: this.formData.isActive,
                  };
                  this.setSecondaryDetails(obj);
                  this.secondaryPassengerDetails =
                    this.getSecondaryPassengerDetails;

                  document
                    .getElementById("passportPhoto")
                    .removeAttribute("src");
                  document
                    .getElementById("passportPhoto")
                    .classList.remove("d-block");
                  document
                    .getElementById("passportPhoto")
                    .classList.add("d-none");
                  if (this.checkUserLogin) {
                    setTimeout(() => {
                      window.location.href = "#confirmationMainSec";
                    }, 500);
                  }
                  this.clearTextFields();
                } catch (e) {}
              }
            }
          }
        } else {
          this.loading = false;
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Please enter your details.",
          });
          this.pDep_Time ? "" : time.focus();
          this.pDep_Date ? "" : date.focus();
          // this.pFlightNumber ? "" : flightNumber.focus();
          // this.pPassportNumber ? "" : passNumber.focus();
          this.pAddress ? "" : address.focus();
          this.pContact ? "" : phone.focus();
          this.pEmail ? "" : pEmail.focus();
          this.pLastName ? "" : lName.focus();
          this.pFirstName ? "" : fName.focus();
        }
      } else {
      }
    },

    updateSecondaryPassenger() {
      this.requardFile = false;
      const fName = document.getElementById("fName");
      const lName = document.getElementById("lName");
      const pEmail = document.getElementById("email");
      const phone = document.getElementById("phone");
      const address = document.getElementById("address");
      const passNumber = document.getElementById("passNumber");
      const flightNumber = document.getElementById("flightNumber");
      const date = document.getElementById("date");
      const time = document.getElementById("time");

      if (this.checkSecondaryEdit) {
        VueSweetalert2.fire({
          title: "Are you sure?",
          text: "You want to update your details!",
          showCancelButton: true,
          confirmButtonColor: "#006203",
          cancelButtonColor: "#db0e03",
          confirmButtonText: "Yes, Update it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;

            const localPath = window.location.href;
            const localProfile = localPath + "img/avetar.142bfcda.svg";

            if (
              this.formData.passangerFirstName &&
              this.formData.passangerLastName &&
              this.formData.passangerEmail &&
              this.formData.passangerContactNumber &&
              this.formData.passangerAddress &&
              // this.formData.passangerPassportNumber &&
              this.formData.passangerFlightNumber &&
              this.formData.passangeDepDate &&
              this.formData.passangeDepTime
            ) {
              const profileImg = this.formData.passengerProfile;
              const pPassportPhoto = this.imageUrl;

              // if (profileImg === localProfile) {
              //   this.loading = false;
              //   VueSweetalert2.fire({
              //     toast: true,
              //     position: 'top-end',
              //     showConfirmButton: false,
              //     timer: 3000,
              //     icon: 'error',
              //     title: 'Please upload your profile image.!',
              //   });

              //   return;
              // }
              this.loading = false;

              const { isInvalid, errors } = validateGeneralDetailsInput(
                this.formData
              );

              if (isInvalid) {
                this.errors = errors;
                this.errors.passangerFirstName ? fName.focus() : "";
                this.errors.passangerLastName ? lName.focus() : "";
                this.errors.passangerEmail ? pEmail.focus() : "";
                this.errors.passangerContactNumber ? phone.focus() : "";
                this.errors.passangerAddress ? address.focus() : "";
                // this.errors.passangerPassportNumber ? passNumber.focus() : "";
                // this.errors.passangerFlightNumber ? flightNumber.focus() : "";
                this.errors.passangeDepDate ? date.focus() : "";
                this.errors.passangeDepTime ? time.focus() : "";
                this.loading = false;
              } else {
                this.errors = {};

                this.formData.passangerPassportImage = this.imageUrl;
                try {
                  if (this.passengerId > 0) {
                    this.secondaryPassengerDetails =
                      this.secondaryPassengerDetails.map((item, index) => ({
                        ...item,
                        passangerFirstName:
                          item.passangerID == this.passengerId
                            ? this.formData.passangerFirstName
                            : item.passangerFirstName,
                        passangerLastName:
                          item.passangerID == this.passengerId
                            ? this.formData.passangerLastName
                            : item.passangerLastName,
                        passangerPassportNumber:
                          item.passangerID == this.passengerId
                            ? this.formData.passangerPassportNumber
                            : item.passengerPassportNumber,
                      }));

                    // this.secondaryPassengerDetails.splice(
                    //   this.secPassengerIndex,
                    //   1
                    // );

                    // this.setUpdateSecondaryDetails(this.formData);

                    // this.secondaryPassengerDetails =
                    //   this.getSecondaryPassengerDetails;
                    this.checkSecondaryEdit = false;

                    document
                      .getElementById("passportPhoto")
                      .removeAttribute("src");
                    document
                      .getElementById("passportPhoto")
                      .classList.remove("d-block");
                    document
                      .getElementById("passportPhoto")
                      .classList.add("d-none");

                    // document.getElementById(
                    //   "primaryPassengerButton"
                    // ).innerText = "Add New Passenger";

                    if (this.checkUserLogin) {
                      setTimeout(() => {
                        window.location.href = "#confirmationMainSec";
                      }, 500);
                    }

                    this.clearTextFields();
                  }
                } catch (e) {}
              }
            } else {
              this.loading = false;
              VueSweetalert2.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: "Please enter your details.",
              });
              this.pDep_Time ? "" : time.focus();
              this.pDep_Date ? "" : date.focus();
              // this.pFlightNumber ? "" : flightNumber.focus();
              // this.pPassportNumber ? "" : passNumber.focus();
              this.pAddress ? "" : address.focus();
              this.pContact ? "" : phone.focus();
              this.pEmail ? "" : pEmail.focus();
              this.pLastName ? "" : lName.focus();
              this.pFirstName ? "" : fName.focus();
            }
          }
        });
      } else {
      }
    },
    //clear all general details fields
    clearTextFields() {
      this.submitted = false;
      this.formData = new GeneralDetails();
      if (this.primaryPassengerDetails.length === 0) {
        setTimeout(function () {
          document.getElementById("passportPhoto").src = "";
          document.getElementById("generalDetailsForm").reset();
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: "Please add your secondary passenger details.",
          });
        }, 1000);
      }

      this.formData.passangerFlightNumber =
        this.primaryPassengerDetails[0].passangerFlightNumber;

      this.formData.passangeDepDate =
        this.primaryPassengerDetails[0].passangeDepDate;

      this.formData.passangeDepTime =
        this.primaryPassengerDetails[0].passangeDepTime;

      this.formData.passangerFlightNumber = "";
    },

    loadBookingSummary() {
      let check = document.getElementById("all").checked;
      if (check) {
        const allAgreement = document.getElementsByName("verificationAgree");
        let checkOnceNotAgrred = true;
        for (const checkbox of allAgreement) {
          if (!checkbox.checked) {
            checkOnceNotAgrred = false;
            break;
          }
        }

        if (checkOnceNotAgrred) {
          this.setVerifyLuggageImage(checkOnceNotAgrred);
          const primaryPassenger = this.primaryPassengerDetails[0];
          const pickUplocation = this.$store.getters.pickupLocation;
          const dropOffLocation =
            this.$store.getters.getDropOffLocationInputDetails;
          const LuggageDetailsArray = this.getLuggageImageDetails;
          this.setGeneralDetails(this.primaryPassengerDetails[0]);

          const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
          var codeObj = {
            userId: currentUser.userId,
          };
          this.setConfirmUserDetails(codeObj);
          var pickUpDateTime =
            moment(
              pickUplocation.pickup_Date + " " + pickUplocation.pickup_Time
            ).format("YYYY-MM-DDTHH:mm:ss") + "Z";
          var dropOffDateTime =
            moment(
              dropOffLocation.dropOffDate + " " + dropOffLocation.dropOffTime
            ).format("YYYY-MM-DDTHH:mm:ss") + "Z";

          if (
            primaryPassenger &&
            pickUplocation &&
            dropOffLocation &&
            LuggageDetailsArray
          ) {
            window.location.href = "#paymentOptionSection";
            //   }
            // })
            // .catch((error) => {
            //   console.error(error);
            // });

            this.$store.dispatch("setDiaplaySummary", true);
            document
              .getElementById("confirmationMainSec")
              .classList.add("d-none");
            document
              .getElementById("confirmationMainSec")
              .classList.remove("d-block");
            this.springLoader = true;
            setTimeout(() => {
              this.springLoader = false;
              document
                .getElementById("paymentOptionMainSec")
                .classList.remove("d-none");
              document
                .getElementById("paymentOptionMainSec")
                .classList.add("d-block");
            }, 800);
          } else {
          }

          if (
            primaryPassenger &&
            pickUplocation &&
            dropOffLocation &&
            dropOffLocation
          ) {
          } else {
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Please add data.",
              icon: "warning",
            });
          }
        } else {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Please select all verifications.",
            icon: "warning",
          });
        }
      } else {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = true;
        }
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          title: "Please select all verifications.",
          icon: "warning",
        });
      }
    },

    bindLoggedPrimaryPassenger() {},
  },
};
</script>

<style scoped>
#loading {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 25%; */
}
.btn-previous {
  background: #ffffff;
  color: #595f69;
  width: 110px;
  height: 38px;
  border: 1px solid #e8e9eb;
  border-radius: 13px;
  box-shadow: none !important;
}

.btn-next {
  background: #006203;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 13px;
  box-shadow: none !important;
}
.btn-next:hover {
  color: #fff;
  background: #1e8722;
}
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 20px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}

.swal2-confirm {
  background-color: #006203 !important;
  border-radius: 20px;
}

.swal2-cancel {
  border-radius: 0.25em !important;
}

.swal2-styled.swal2-confirm {
  border-radius: 0.25em !important;
}

.uploadTextColor {
  color: #006203;
}

.modal-backdrop.show {
  opacity: 0 !important;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0 !important;
  width: 100vw;
  height: 100vh;
}
.passportImgPreview {
  width: 300px;
  height: 100px;
  object-fit: cover;
  margin-top: 30px;
  background-position: 50% 50%;
  background-size: cover;
}
.dragging {
  background: #006203;
}
.generalTitle {
  color: #006203;
  font-weight: 500;
  font-family: Poppins;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
}
input:focus {
  outline: none;
  border-color: #006203;
  box-shadow: none;
}
input[type="text"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="select"] {
  border-radius: 13px;
}
#title {
  border-radius: 13px;
  height: 38px;
}
.passportPhotoDiv {
  border: 2px dashed #c6c9cc;
  height: 100px;
  background: #d1eed2;
  box-sizing: border-box;
  border-radius: 14px;
  opacity: 0.8;
  margin-left: 0;
}
.changeBackground {
  border: 2px solid #006203;
}
.passportImg {
  border: none;
  border-radius: 0 !important;
  text-align: center;
  background: none;
}
.btnAddNew {
  color: #006203;
  padding-left: 4px;
}
.btnAddNew img {
  padding-top: 0 !important;
  border-radius: 10px;
  margin-bottom: 3px;
}
.btnAddPre {
  color: #8a9099;
}
.btnAddPre img {
  padding-top: 0 !important;
  border-radius: 10px;
  margin-bottom: 3px;
}
.primary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 45px;
  border-radius: 10px;
  background: #d1eed2;
  color: #006203;
  font-size: 8px;
}
.primary p {
  text-align: center;
  margin-bottom: 0;
}
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
table tr {
  padding: 0.35em;
}
table th,
table td {
  padding: 0.625em 0.625em 0.625em 0;
  text-align: left;
}
table td {
  font-size: 0.75em;
}
table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
}
.passport_Input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (max-width: 768px) {
  input {
    margin-bottom: 15px;
  }
  .addressDiv {
    margin-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .btnAddPre {
    left: 0;
  }
  .addressDiv {
    margin-left: 0;
  }
  .generalTitle {
    margin-left: 0;
    margin-bottom: 50px;
  }
  .btnAddNew {
    font-size: 13px;
  }
  .btnAddPre {
    font-size: 13px;
  }
  table {
    border: 0;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
@media screen and (max-width: 425px) {
  .btnAddPre {
    padding-left: 0;
  }
  .btnAddNew {
    padding-left: 0;
  }
  table td {
    padding: 0.625em 0.625em 0.625em 0;
    text-align: right;
  }
  .tblBtnDelete {
    padding-right: 0;
  }
}
</style>
