export default class GeneralDetails {
  constructor(
    title,
    passangerID,
    passengerProfile,
    passangerFirstName,
    passangerLastName,
    passangerEmail,
    passangerContactNumber,
    passangerAddress,
    passangerPassportNumber,
    passangerPassportImage,
    passangerIsPrimary,
    passangerFlightNumber,
    passangeDepDate = new Date().toLocaleDateString(),
    passangeDepTime = new Date().toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }),
    isActive
  ) {
    this.passangerID = 0;
    this.title = "Mr";
    this.passengerProfile = passengerProfile;
    this.passangerFirstName = passangerFirstName;
    this.passangerLastName = passangerLastName;
    this.passangerEmail = passangerEmail;
    this.passangerContactNumber = passangerContactNumber;
    this.passangerAddress = passangerAddress;
    this.passangerPassportNumber = passangerPassportNumber;
    this.passangerPassportImage = passangerPassportImage;
    this.passangerIsPrimary = 0;
    this.passangerFlightNumber = passangerFlightNumber;
    this.passangeDepDate = passangeDepDate;
    this.passangeDepTime = passangeDepTime;
    this.isActive = true;
  }
}
