<template>
  <div class="pb-4">
    <PaymentCard
      v-for="(payment, index) in payments"
      :key="index"
      :payment="payment"
    />
  </div>
</template>

<script>
import PaymentCard from "./paymentCard";
export default {
  name: "paymentCardList",
  components: { PaymentCard },
  props: {
    payments: {
      type: Array,
    },
  },
};
</script>

<style scoped></style>
