<template>
  <a :href="previous" class="btn btnStylePrev">{{ buttonName }}</a>
</template>

<script>
export default {
  name: "ButtonPrev",
  props: {
    buttonName: String,
    previous: String,
  },
};
</script>

<style scoped>
.btnStylePrev {
  background: #ffffff;
  color: #595f69;
  width: 110px;
  height: 38px;
  border: 1px solid #e8e9eb;
  border-radius: 13px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
@media all and (max-width: 425px) {
  .btnStylePrev {
    width: 110px;
    height: 38px;
    margin-bottom: 5px;
  }
}
</style>
