import validator from "validator";

const validatePickUplocationDetailsInput = (data) => {
  let errors = {};
  const {
    location,
    remark,
    pickup_Date,
    pickup_Time,
    care_Taker_Name,
    care_Taker_Contact_Number,
    isLanguageEN,
  } = data;

  if (location === undefined) {
    errors.location = isLanguageEN ? "Input your location" : "أدخل موقعك";
  } else if (validator.isEmpty(location)) {
    errors.location = isLanguageEN ? "Input your location" : "أدخل موقعك";
  }

  if (pickup_Date === undefined) {
    errors.pickUpdate = isLanguageEN
      ? "Input your pickup date"
      : "أدخل تاريخ الاستلام";
  } else if (validator.isEmpty(pickup_Date)) {
    errors.pickUpdate = isLanguageEN
      ? "Input your pickup date"
      : "أدخل تاريخ الاستلام";
  }

  if (validator.isEmpty(pickup_Time)) {
    errors.pickUpTime = isLanguageEN
      ? "Input your pickup time"
      : "أدخل وقت الاستلام";
  } else if (pickup_Time === undefined) {
    errors.pickUpTime = isLanguageEN
      ? "Input your pickup time"
      : "أدخل وقت الاستلام";
  }
  if (remark === undefined) {
    errors.remark = isLanguageEN ? "Input your landmark" : "أدخل مَعلمك";
  } else if (validator.isEmpty(location)) {
    errors.remark = isLanguageEN ? "Input your landmark" : "أدخل مَعلمك";
  }
  if (
    care_Taker_Contact_Number === undefined ||
    care_Taker_Contact_Number === ""
  ) {
  } else if (
    !validator.isEmpty(care_Taker_Name) &&
    !/^\+[1-9]{1}[0-9]{3,14}$/.test(care_Taker_Contact_Number)
  ) {
    errors.care_Taker_Contact_Number = isLanguageEN
      ? "Input contact number with country code eg. +966"
      : "أدخل رقم الاتصال مع رمز البلد ، مثل +966";
  }

  return {
    isInvalid: Object.keys(errors).length > 0,
    errors: errors,
  };
};

export default validatePickUplocationDetailsInput;
