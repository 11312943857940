<template>
  <abbr
    :title="
      this.$store.getters.getPaymentType != ''
        ? 'Pay at the Baggage Collection'
        : ''
    "
  >
    <a v-on:click="save" :href="next" class="btn btnStyleNext">
      {{ this.$store.getters.getPaymentType != "" ? "Pay" : buttonName }}
    </a>
  </abbr>
</template>

<script>
export default {
  name: "ButtonNext",
  props: {
    buttonName: String,
    next: String,
    luggageProp: String,
    save: Function,
  },
  data() {
    return {
      actionName: this.luggageProp,
      paymentType: "",
    };
  },
  methods: {},
};
</script>

<style scoped>
.btnStyleNext {
  background: #006203;
  color: #ffffff;
  width: 110px;
  height: 38px;
  border-radius: 13px;
}
.btnStyleNext:hover {
  color: #fff;
  background: #1e8722;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

@media all and (max-width: 425px) {
  .btnStyleNext {
    width: 110px;
    height: 38px;
    margin-bottom: 5px;
  }
}
</style>
