<template>
  <div class="row mt-4 pb-lg-4 mx-0">
    <div
      class="
        col-6
        row
        flex-row
        d-flex
        justify-content-lg-end justify-content-md-end justify-content-sm-center
        ps-0
        mx-0
      "
    >
      <div
        class="
          form-group
          w-100
          row
          flex-row
          d-flex
          justify-content-lg-end
          justify-content-md-end
          justify-content-sm-center
          mx-0
        "
      >
        <ButtonPrev :previous="previous" buttonName="Previous" />
      </div>
    </div>
    <div
      class="
        col-6
        row
        flex-row
        d-flex
        justify-content-lg-start
        justify-content-md-start
        justify-content-sm-center
        ps-0 ps-md-3 ps-lg-3
        mx-0
      "
    >
      <div
        class="
          form-group
          w-100
          row
          flex-row
          d-flex
          justify-content-lg-start
          justify-content-md-start
          justify-content-sm-center
          mx-0
        "
      >
        <ButtonNext
          :save="save"
          :luggageProp="luggageProp"
          :next="next"
          :buttonName="buttonName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonPrev from "./ButtonPrev";
import ButtonNext from "./ButtonNext";

export default {
  name: "ButtonList",
  components: {
    ButtonPrev,
    ButtonNext,
  },
  props: {
    previous: {
      type: String,
    },
    next: {
      type: String,
    },
    luggageProp: {
      type: String,
    },
    save: {
      type: Function,
    },
    buttonName: {
      type: String,
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 599px) and (max-width: 768px) {
}
@media (min-width: 425px) and (max-width: 599px) {
}

@media (min-width: 200px) and (max-width: 425px) {
}
</style>
