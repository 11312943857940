<template>
  <div class="col-10 offset-lg-1 mapDiv" ref="mapDiv"></div>
</template>

<script>
export default {
  name: 'Map',
  data() {
    return {};
  },
};
</script>

<style scoped>
.mapDiv {
  border-radius: 15px;
  height: 300px;
  border: 1px solid #e8e9eb;
  margin-top: 15px;
  margin-bottom: 20px;
}
.mapImg {
  width: 100%;
  border-radius: 15px;
  height: 300px;
}
</style>
