<template>
  <div id="loading">
    <DoubleBounce></DoubleBounce>
  </div>
</template>

<script>
import { DoubleBounce } from "vue-loading-spinner";

export default {
  components: {
    DoubleBounce,
  },
};
</script>

<style scoped>
#loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
}
</style>
