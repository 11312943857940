<template>
  <div id="welcomeSectionDiv" class="d-none">
    <div
      class="row d-flex justify-content-center align-item-center welcomSecDiv"
    >
      <div class="row d-flex justify-content-end align-self-end">
        <div class="col-2">
          <select
            id="languageSelector"
            aria-label="Default select example"
            class="form-select"
            name="selectLang"
            @change="setLanguage($event)"
          >
            <option value="EN">English</option>
            <option id="arabic" value="AR">اَلْعَرَبِيَّةُ</option>
          </select>
        </div>
      </div>
      <div class="col-lg-12 d-flex justify-content-center align-item-center">
        <SmallCircle :text="welcome.circleText" />
      </div>
      <div class="col-lg-12 welcomeText">
        <h4>
          <b>{{ welcome.welcomeText }}</b>
        </h4>
        <h4>
          <b>{{ welcome.welcomeText2 }}</b>
        </h4>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-4">
      <div class="col-lg-3 d-flex justify-content-around align-item-center">
        <a
          class="btn btn-getStart"
          @click="showGeneralDetailsSection"
          href="#generalDSec"
          >{{ welcome.getStart }}</a
        >
        <p class="textEnter mb-0 mt-2">
          {{ welcome.pressEnter }}
          <img :src="enterIcon" alt="" class="enterIcon" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import SmallCircle from "../../../common/components/booking/SmallCircle.vue";
import enterIcon from "../../../assets/Icons/enterIcon.svg";

export default {
  name: "WelcomeSection",
  components: {
    SmallCircle,
  },
  data: function () {
    return {
      enterIcon,
      welcome: this.$store.getters.getWelcomeSecData,
      language: "",
    };
  },
  methods: {
    showGeneralDetailsSection() {
      document.getElementById("generalDivSection").classList.remove("d-none");
      document.getElementById("generalDivSection").classList.add("d-block");
    },
    setLanguage: (event) => {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
      // this.language = event.target.language
    },
  },
  mounted() {
    let elementById = document.getElementById("languageSelector");
    this.language = elementById.value;
  },
};
</script>

<style scoped>
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.welcomSecDiv {
  margin-top: 50px;
}

.welcomeText {
  text-align: center;
  color: #006203;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-getStart {
  width: 134px;
  height: 38px;
  background: #006203;
  color: #ffffff;
  border-radius: 13px;
  margin-bottom: 0;
}

.enterIcon {
  width: 16px;
  height: 18px;
}

.textEnter {
  color: #757575 !important;
}

@media all and (max-width: 1024px) {
  .btn-getStart {
    width: 83px;
    height: 28px;
    font-size: 10px;
  }

  .textEnter {
    font-size: 11px;
    color: #757575;
  }

  .welcomSecDiv {
    margin-top: 50px;
  }

  .welcomeText {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media all and (max-width: 768px) {
  .welcomSecDiv {
    margin-top: 50px;
  }

  .welcomeText {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media all and (max-width: 425px) {
  .btn-getStart {
    width: 83px;
    height: 28px;
    font-size: 10px;
  }

  .textEnter {
    font-size: 11px;
  }

  .welcomSecDiv {
    margin-top: 50px;
  }

  .welcomeText {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
