import validator from "validator";

const validateDropOffLocationDetailsInput = (data) => {
  let errors = {};
  const { location, dropOffDate, dropOffTime, flightNumber, isLanguageEN } =
    data;

  // if (validator.isEmpty('' + location)) {
  //   errors.location = 'Input your location';
  // }
  if (validator.isEmpty(dropOffDate)) {
    errors.dropOffdate = isLanguageEN
      ? "Input your dropOffDate"
      : "أدخل تاريخ الإسقاط الخاص بك";
  }
  if (validator.isEmpty(dropOffTime)) {
    errors.dropOffTime = isLanguageEN
      ? "Input your dropOffTime"
      : "أدخل دروب أوف تايم الخاص بك";
  }

  if (validator.isEmpty(flightNumber)) {
    errors.flightNumber = isLanguageEN
      ? "Input your flight number"
      : "أدخل رقم رحلتك";
  } else if (flightNumber === undefined) {
    errors.flightNumber = isLanguageEN
      ? "Input your flight number"
      : "أدخل رقم رحلتك";
  } else if (flightNumber.startsWith(" ")) {
    errors.flightNumber = isLanguageEN
      ? "Input your flight number"
      : "أدخل رقم رحلتك";
  }

  return {
    isInvalid: Object.keys(errors).length > 0,
    errors: errors,
  };
};

export default validateDropOffLocationDetailsInput;
